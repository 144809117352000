/* eslint @typescript-eslint/no-explicit-any: 0 */
const IMAGES_FOR_PRELOAD: string[] = [
  "/images/loader.gif",
  "/icons/icon-x.svg",
  "/icons/gallery-white.svg",
  "/icons/camera-black.svg",
  "/logo-black.png",
];

export const preLoadImages = async () => {
  const promises: Promise<any>[] = [];
  for (const src of IMAGES_FOR_PRELOAD) {
    const p = new Promise<any>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(true);
      };
      img.src = process.env.PUBLIC_URL + src;
    });
    promises.push(p);
  }
  await Promise.all(promises);
};
preLoadImages().catch();
