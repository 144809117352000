import React from "react";
import { useTranslation } from "react-i18next";
import { PartOfLook, SortMethods } from "../../Types/discovery";
import SortDropdown from "./SortDropdown/SortDropdown";
import { ReactNode } from "react";
import LookTypeButton from "./LookTypes/LookTypeButton/LookTypeButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updatePartOfLook } from "../../redux/discovery";

interface SortButtonsProps {
  onChangeFilterMethod: (
    filter: SortMethods,
    partOfLook: PartOfLook | null,
  ) => void;
  isZoomed: boolean;
}

const SortButtons: React.FC<SortButtonsProps> = ({
  onChangeFilterMethod,
  // isZoomed,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentType = useAppSelector(
    (state) => state.discovery.initialPartOfLook,
  );
  const combos = useAppSelector((state) => state.discovery.comboData);

  const onChangeFilter = (type: PartOfLook | null) => (filter: SortMethods) => {
    onChangeFilterMethod(filter, type);
  };

  const onChangePart = (type: PartOfLook) => (e?: React.MouseEvent) => {
    e?.stopPropagation();
    dispatch(updatePartOfLook(type));
  };

  const getText = (): ReactNode => {
    return (
      <div className="relative flex flex-row gap-2 ltr:left-[4%] rtl:right-[4%] overflow-visible">
        {Object.values(PartOfLook).map((key: PartOfLook, index: number) => {
          const shouldShowButton =
            !!combos && Object.keys(combos[key]).length > 0;
          return (
            shouldShowButton && (
              <LookTypeButton
                key={key}
                active={key === currentType}
                onClick={onChangePart(key)}
                text={
                  (
                    t("discovery.partOfLook", {
                      returnObjects: true,
                    }) as string[]
                  )[index]
                }
              />
              // <LookFXDropdown
              //   initialEffect={initialLookEffect}
              //   lookType={key}
              //   isActive={key === currentType}
              //   onChangeType={(effect) => onChangeType(key, effect)}
              //   key={key}
              //   isZoomed={isZoomed}
              //   dropdownButton={
              //     <LookTypeButton
              //       active={key === currentType}
              //       text={
              //         (
              //           t("discovery.lookTypes", {
              //             returnObjects: true,
              //           }) as string[]
              //         )[index]
              //       }
              //     />
              //   }
              // />
            )
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-2 items-center">
      {getText()}
      <div className="ltr:ml-auto rtl:mr-auto flex flex-row gap-2 relative">
        <SortDropdown onChangeFilter={onChangeFilter(currentType)} />
      </div>
    </div>
  );
};

export default SortButtons;
