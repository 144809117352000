import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import Typography from "../../../Components/Common/Typography/Typography";
import { ProductDiscoveryItem } from "../../../api/types";
import {
  checkProduct,
  findProductInDiscovery,
  getIsInFavoritesMessage,
  sendAddToFavoritesMessage,
} from "../../../utils/discoveryHelper";
import { CURRENCY_SYMBOL, IS_ADD_TO_CART } from "../../../config";
import Button from "../../../Components/Common/Button/Button";
import HeartIcon from "../../../Components/Common/CustomIcons/HeartIcon";
import AddToCartButton from "../AddToCartButton";

const ProductView = ({ item_id }: { item_id: string }) => {
  const { t } = useTranslation();
  const catalog = useAppSelector((state) => state.discovery.catalog);
  const [item, setItem] = useState<ProductDiscoveryItem>();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    getIsInFavoritesMessage(item_id);
    const itemData = catalog[findProductInDiscovery(item_id, catalog)];
    setItem(itemData);

    const handleFavoritesMessage = (event: any) => {
      console.log({ event });
      item_id &&
        event.data &&
        event.data.name === "mistrixIsInFavorites" &&
        event.data.payload.productId === item_id &&
        setIsSaved(event.data.payload.isInFavorites);
    };

    window.addEventListener("message", handleFavoritesMessage);

    return () => {
      window.removeEventListener("message", handleFavoritesMessage);
    };
  }, [item_id, catalog]);

  const onSaveToFavorites = () => {
    const newState = !isSaved;
    setIsSaved(newState);
    sendAddToFavoritesMessage(item_id, newState);
  };

  if (!item) <></>;

  const dataLine = (
    title: string,
    value?: string | undefined | null,
    row?: boolean,
  ) =>
    !!value &&
    value !== "-" && (
      <div
        className={`flex ${row ? "flex-row items-center" : "flex-col"} gap-4`}
      >
        <Typography className="font-bold">{title}</Typography>
        <Typography>{value}</Typography>
      </div>
    );

  const images = item ? (item?.img_url as unknown as string[]) : [];

  return (
    <div className="flex flex-col gap-4 items-start p-4 h-full w-full justify-between">
      <div className="flex flex-col h-full gap-4 w-full overflow-y-scroll">
        <div
          className={`w-full items-center flex flex-row gap-2 p-2 min-h-[10rem] max-h-40 overflow-x-scroll shadow-images rounded-xl bg-white ${
            images?.length === 1 && "justify-center"
          }`}
        >
          {images?.map((img, i: number) => (
            <img
              key={i}
              src={img}
              alt=""
              className="min-h-[9rem] max-h-36 w-auto object-contain"
            />
          ))}
        </div>
        <div className="flex flex-row justify-between items-center w-full gap-4">
          <Typography size="lg" className="font-bold leading-[2rem] text-wrap">
            {item?.product_name}
          </Typography>
          <div
            role="button"
            tabIndex={0}
            onClick={onSaveToFavorites}
            className="w-fit h-fit"
          >
            {HeartIcon(`w-8 h-8 ${isSaved ? "text-black" : "text-white"}`)}
          </div>
        </div>
        <Typography size="lg" className="font-bold">
          {item?.price} {CURRENCY_SYMBOL}
        </Typography>
        {dataLine(t("discovery.catalog.color"), item?.color_description, true)}
        {dataLine(t("discovery.catalog.details"), item?.product_description)}
      </div>
      <div
        className={`flex flex-row gap-4 w-full sticky bottom-0 ${
          IS_ADD_TO_CART ? "justify-between" : "justify-center"
        }`}
      >
        <AddToCartButton
          className="w-full !h-10"
          onBuyNow={checkProduct(item!)}
          itemsIds={[item_id]}
        />
        {IS_ADD_TO_CART && (
          <Button
            className="py-0 w-full h-10"
            size="small"
            action={checkProduct(item!)}
            bgColor="ghost"
          >
            <Typography className="py-0 leading-[normal]" size="sm">
              {t("discovery.catalog.moreInfo")}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductView;
