import React, { useRef } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  setIsTargetGallery,
  setProcessing,
  setSourceUploaded,
  setSourceValid,
  setTargetUploaded,
  setTargetValid,
} from "../../redux/flow";
import {
  createImageBlob,
  cropImage,
  getImageUrl,
  uploadValidatedImageToStorage,
  validateImage,
} from "../../utils/imageDataHandler";
import {
  updateTargetBlob,
  updateTarget,
  updateCurrentSource,
  updatePreviousSource,
  updateCurrentSourceBlob,
  updatePreviousSourceBlob,
} from "../../redux/images";
import { useTranslation } from "react-i18next";
import Typography from "../Common/Typography/Typography";

interface Props {
  isSource: boolean;
  sizeTrigger: boolean;
  text?: string;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
}

const UploadImage = ({
  isSource,
  sizeTrigger,
  text,
  setHasError,
  setErrorMessage,
  errorMessage,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const galleryRef = useRef<HTMLInputElement>(null);

  const validateAndUploadLook = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      dispatch(setProcessing(true));
      try {
        const imageBlob = await createImageBlob(selectedFile);
        const imageValidationRes = await validateImage(imageBlob, false);
        if (imageValidationRes.data?.validateImages?.is_valid) {
          // TODO: add GA
          const croppedImage = await cropImage(
            imageBlob,
            imageValidationRes.data.validateImages.crop_idx,
          );
          const url = await getImageUrl(croppedImage);
          dispatch(
            updateTarget({
              s3: {
                Bucket: "",
                Key: "",
              },
              url,
            }),
          );
          dispatch(updateTargetBlob(croppedImage));
          dispatch(setTargetValid(true));
          dispatch(setProcessing(false));
          dispatch(setIsTargetGallery(false));
          const uploadRes = await uploadValidatedImageToStorage(
            croppedImage,
            false,
          );
          if (uploadRes.data?.uploadImageToStorage?.success) {
            dispatch(
              updateTarget({
                s3: {
                  Bucket: uploadRes.data.uploadImageToStorage.bucket,
                  Key: uploadRes.data.uploadImageToStorage.key,
                },
                url,
              }),
            );
            dispatch(setTargetUploaded(true));
          } else {
            dispatch(setTargetUploaded(false));
          }
        } else {
          dispatch(setProcessing(false));
          dispatch(setTargetValid(false));
          setHasError(true);
          setErrorMessage(t("error.validation.text"));
        }
      } catch (error) {
        setHasError(true);
        // console.error("Error while creating resized blob:", error);
        dispatch(setTargetValid(false));
        // TODO: add error logs
        setErrorMessage("Network error");
      }
    }
  };
  const validateAndUploadSelfie = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      dispatch(setProcessing(true));
      try {
        const imageBlob = await createImageBlob(selectedFile);
        const imageValidationRes = await validateImage(imageBlob, true);
        if (imageValidationRes.data?.validateImages?.is_valid) {
          // TODO: add GA
          const croppedImage = await cropImage(
            imageBlob,
            imageValidationRes.data.validateImages.crop_idx,
          );
          const url = await getImageUrl(croppedImage);
          const source = {
            s3: {
              Bucket: "",
              Key: "",
            },
            url,
          };
          dispatch(updateCurrentSource(source));
          dispatch(updatePreviousSource(source));
          dispatch(updateCurrentSourceBlob(croppedImage));
          dispatch(updatePreviousSourceBlob(croppedImage));
          dispatch(setSourceValid(true));
          dispatch(setProcessing(false));
          const uploadRes = await uploadValidatedImageToStorage(
            croppedImage,
            true,
          );
          if (uploadRes.data?.uploadImageToStorage?.success) {
            dispatch(
              updateCurrentSource({
                s3: {
                  Bucket: uploadRes.data.uploadImageToStorage.bucket,
                  Key: uploadRes.data.uploadImageToStorage.key,
                },
                url,
              }),
            );
            dispatch(
              updatePreviousSource({
                s3: {
                  Bucket: uploadRes.data.uploadImageToStorage.bucket,
                  Key: uploadRes.data.uploadImageToStorage.key,
                },
                url,
              }),
            );
            dispatch(setSourceUploaded(true));
          } else {
            dispatch(setSourceUploaded(false));
          }
        } else {
          dispatch(setProcessing(false));
          dispatch(setSourceValid(false));
          setHasError(true);
          setErrorMessage("Invalid image");
        }
      } catch (error) {
        setHasError(true);
        dispatch(setSourceValid(false));
        // TODO: add error logs
        setErrorMessage("Network error");
      }
    }
  };

  return (
    <div
      className="flex flex-col items-center gap-2 h-full bg-white rounded-3xl cursor-pointer justify-center shadow-lg"
      onClick={() => galleryRef.current?.click()}
      role="none"
    >
      <img
        className={sizeTrigger ? "w-7" : "w-5"}
        src={process.env.PUBLIC_URL + "/icons/gallery-gray.svg"}
        alt="gallery"
      />
      {text && (
        <Typography
          color={"text-gray-900"}
          className="text-center text-xs px-2 pt-2"
        >
          {text}
        </Typography>
      )}
      <input
        ref={galleryRef}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={isSource ? validateAndUploadSelfie : validateAndUploadLook}
      />
    </div>
  );
};

export default UploadImage;
