import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "../../Common/Typography/Typography";
import { CURRENCY_SYMBOL } from "../../../config";
import { useAppSelector } from "../../../app/hooks";
import { ProductDiscoveryItem } from "../../../api/types";
import ProductDialog from "../../ProductDialog/ProductDialog";
import { findProductInDiscovery } from "../../../utils/discoveryHelper";
import AddToCartButton from "../AddToCartButton";

export interface CatalogItemProps {
  item_id: string;
  onAddToCart: (product: string) => void;
  match: number;
  onProductClick: (id: string) => () => void;
}

// const generateColor = (color_properties: string | undefined) => {
//   if (color_properties) {
//     return `#${color_properties.split("-")[0]}`;
//   } else return "rgb(255, 255, 255)";
// };

const SingleCatalogItem: React.FC<CatalogItemProps> = ({
  item_id,
  onAddToCart,
  match,
  onProductClick,
}: CatalogItemProps) => {
  // const addToCart = () => {
  //   const req = {
  //     parent_sku: [item?.parent_sku],
  //     color_code: [item?.color_code_number],
  //   };
  //   window.parent.postMessage(["mistrixAddToCart", req], "*");
  //   onAddToCart(item_id);
  // };
  const catalog = useAppSelector((state) => state.discovery.catalog);
  const [item, setItem] = useState<ProductDiscoveryItem>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const itemData = catalog[findProductInDiscovery(item_id, catalog)];
    setItem(itemData);
  }, [item_id, catalog]);

  const onBuyNow = () => setIsDialogOpen(true);

  return (
    <div>
      <ProductDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        products={[item ? item : catalog[0]]}
      />
      <div className="flex flex-row items-center">
        <div
          id={"mistrix-item-" + item_id}
          className="drop-shadow-[0_0_6.67px_rgba(0,0,0,0.10)] bg-white h-32 rounded-2xl w-full flex mb-4 flex-row gap-[10%] justify-center items-center"
        >
          <div className="flex flex-col items-center justify-center relative rtl:right-8 ltr:left-8">
            <Typography size="2xl">{Math.floor(match * 100)}%</Typography>
            <Typography size="sm">{t("discovery.catalog.match")}</Typography>
            {/* <div className="flex flex-row gap-1 mt-1">
              <Circle
                size="sm"
                id={`info-${item?.product_id}`}
                className="bg-white hover:bg-gray-100 cursor-pointer shadow-md"
              >
                <Typography className="cursor-pointer mt-2" size="xs">
                  i
                </Typography>
              </Circle>
              <Circle
                size="sm"
                id={`video-${item?.product_id}`}
                className="bg-white hover:bg-gray-100 cursor-pointer shadow-md"
              >
                <img
                  src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                  alt="video"
                />
              </Circle>
            </div> */}
          </div>
          <div className="flex flex-col gap-1 mt-3">
            <div
              className="flex flex-row items-center gap-2"
              role="none"
              onClick={onProductClick(item?.product_id ?? "")}
            >
              <div className="ltr:ml-2 rtl:mr-2">
                <img
                  src={item?.img_url[0]}
                  alt=""
                  className="max-w-[90px] max-h-[80px] h-24"
                />
              </div>
              <div className=" flex flex-col items-start min-w-[130px] min-h-[55px]">
                <Typography
                  className="font-bold line-clamp-2 max-w-[110px]"
                  size="md"
                >
                  {item?.product_name}
                </Typography>
                <Typography size="sm">{item?.color_name}</Typography>
                <Typography size="sm">{item?.brand}</Typography>
                {/* <Typography size="sm">{item?.color_code_name}</Typography> */}
                {/* <div
                  style={{
                    backgroundColor: generateColor(item?.color_properties),
                  }}
                  className="w-1/4 h-3 rounded-xl border-gray-200 border-[1px]"
                /> */}
              </div>
            </div>
            <div className="flex justify-end items-center flex-row gap-6 mb-2 w-[85%]">
              {/* <Typography
                className="whitespace-break-spaces mt-1 -ml-4"
                size="xs"
              >
                {t("discovery.catalog.price", {
                  currency: CURRENCY_SYMBOL,
                  price: parseFloat(
                    item && item.price ? item.price : "0",
                  ).toFixed(2),
                })}
              </Typography> */}
              <AddToCartButton onBuyNow={onBuyNow} itemsIds={[item_id]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCatalogItem;
