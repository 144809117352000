import React from "react";

const LipsIcon = (className: string) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 16.8261C14.0435 16.8261 12.4131 15.5217 9.47827 16.1739C10.4565 15.8478 12.087 14.2174 13.3913 14.2174C14.6957 14.2174 15.5109 14.8696 16.1631 14.8696C16.8152 14.8696 17.3044 14.2174 18.9348 14.2174C20.5652 14.2174 21.2174 15.5217 22.5217 16.1739C20.2391 15.8478 17.9565 16.8261 16 16.8261Z"
        stroke="currentColor"
      />
      <path
        d="M12.5194 9C8.88568 9 6.90367 14.2174 4.26099 16.1739C4.59132 17.4783 7.234 24 16.1531 24C25.0721 24 28.0451 17.1522 27.7148 16.1739C26.0631 14.5435 22.7598 9 20.1171 9C17.1441 9 16.9784 10.3043 16.3182 10.6304C15.674 10.3043 15.1782 9 12.5194 9Z"
        stroke="currentColor"
      />
      <path
        d="M9.47838 16.1739C8.50012 16.5 7.52186 16.8261 4.26099 16.1739"
        stroke="currentColor"
      />
      <path
        d="M22.5219 16.1739C23.5001 16.5 24.4784 16.8261 27.7393 16.1739"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33618 0.853912H9.86007C10.1362 0.853912 10.3601 1.07777 10.3601 1.35391C10.3601 1.63005 10.1362 1.85391 9.86007 1.85391H8.33618C4.74633 1.85391 1.83618 4.76407 1.83618 8.35392V9.56239C1.83618 9.83853 1.61232 10.0624 1.33618 10.0624C1.06004 10.0624 0.836182 9.83853 0.836182 9.56239V8.35392C0.836182 4.21179 4.19405 0.853912 8.33618 0.853912ZM1.33618 21.9376C1.06004 21.9376 0.836182 22.1614 0.836182 22.4376V23.6461C0.836182 27.7882 4.19404 31.1461 8.33618 31.1461H9.86007C10.1362 31.1461 10.3601 30.9222 10.3601 30.6461C10.3601 30.3699 10.1362 30.1461 9.86007 30.1461H8.33618C4.74633 30.1461 1.83618 27.2359 1.83618 23.6461V22.4376C1.83618 22.1614 1.61232 21.9376 1.33618 21.9376ZM30.6638 21.9376C30.3877 21.9376 30.1638 22.1614 30.1638 22.4376V23.646C30.1638 27.2359 27.2537 30.1461 23.6638 30.1461H22.1399C21.8638 30.1461 21.6399 30.3699 21.6399 30.6461C21.6399 30.9222 21.8638 31.1461 22.1399 31.1461H23.6638C27.806 31.1461 31.1638 27.7882 31.1638 23.646V22.4376C31.1638 22.1614 30.94 21.9376 30.6638 21.9376ZM30.6638 10.0624C30.94 10.0624 31.1638 9.83853 31.1638 9.56239V8.35391C31.1638 4.21178 27.806 0.853912 23.6638 0.853912H22.1399C21.8638 0.853912 21.6399 1.07777 21.6399 1.35391C21.6399 1.63005 21.8638 1.85391 22.1399 1.85391H23.6638C27.2537 1.85391 30.1638 4.76406 30.1638 8.35391V9.56239C30.1638 9.83853 30.3877 10.0624 30.6638 10.0624Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LipsIcon;
