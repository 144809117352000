import React from "react";

const SortIcon = (className: string) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.76904 1.0769V14.2308"
        stroke="CurrentColor"
        strokeLinecap="round"
      />
      <path
        d="M1 12.1538L3.76924 14.923L6.53847 12.1538"
        stroke="CurrentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30762 1.76917H18.9999"
        stroke="CurrentColor"
        strokeLinecap="round"
      />
      <path
        d="M9.30762 5.9231H17.6153"
        stroke="CurrentColor"
        strokeLinecap="round"
      />
      <path
        d="M9.30762 10.0769H14.8461"
        stroke="CurrentColor"
        strokeLinecap="round"
      />
      <path
        d="M9.30762 14.2308H12.0769"
        stroke="CurrentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SortIcon;
