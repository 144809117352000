import React, { useCallback, useEffect, useRef, useState } from "react";
import Typography from "../Components/Common/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ProductDiscoveryItem, SessionsResult } from "../api/types";
import { getCatalog, handleShopThatLook } from "../api/wtmHandler";
import {
  drawImageOnCanvas,
  uploadValidatedImageToStorage,
} from "../utils/imageDataHandler";
import {
  updateCroppedResultImage,
  updateCurrentSource,
  updateCurrentSourceBlob,
  updateTarget,
} from "../redux/images";
import {
  nextStage,
  previousStage,
  setFailed,
  setProcessing,
  setSourceUploaded,
  setTargetUploaded,
} from "../redux/flow";
import { Comboes, LookEffect, LookType } from "../Types/discovery";
import {
  updateCatalog,
  updateComboes,
  updateLookEffect,
  updateLookType,
  updateSourceLipsCoords,
  updateTargetImageCrop,
  updateTargetCoords,
} from "../redux/discovery";
import { BankPhoto } from "../Types/images";
import Loading0Icon from "../Components/Common/CustomIcons/Loading0Icon";
import Loading1Icon from "../Components/Common/CustomIcons/Loading1Icon";
import Loading2Icon from "../Components/Common/CustomIcons/Loading2Icon";
import Loading3Icon from "../Components/Common/CustomIcons/Loading3Icon";
import { collectAnalytics } from "../utils/apiUtils";

const WorkingTheMagic: React.FC = () => {
  const dispatch = useAppDispatch();
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
  const [tryTheLook, setTryTheLook] = useState<SessionsResult>();
  const target = useAppSelector((state) => state.images.target);
  const targetBlob = useAppSelector((state) => state.images.targetBlob);
  const source = useAppSelector((state) => state.images.currentSource);
  const sourceBlob = useAppSelector((state) => state.images.currentSourceBlob);
  const hasUploadedTarget = useAppSelector(
    (state) => state.flow.targetUploaded,
  );
  const hasUploadedSource = useAppSelector(
    (state) => state.flow.sourceUploaded,
  );
  const { t } = useTranslation();

  const sourceRef = useRef(source);
  const hasUploadedSourceRef = useRef(hasUploadedSource);
  const hasUploadedTargetRef = useRef(hasUploadedTarget);
  const tryTheLookRef = useRef(tryTheLook);

  const returnAfterError = useCallback(() => {
    dispatch(setProcessing(false));
    dispatch(setFailed(true));
    dispatch(updateCurrentSource(null));
    dispatch(updateCurrentSourceBlob(null));
    dispatch(previousStage());
  }, [dispatch]);

  useEffect(() => {
    const [pageLocationGA, pageTitleGA] = ["3", "Step 3 - Working the Magic"];

    collectAnalytics("page_view", {
      page_location: pageLocationGA,
      page_title: pageTitleGA,
    });
  }, []);

  useEffect(() => {
    hasUploadedSourceRef.current = hasUploadedSource;
    hasUploadedTargetRef.current = hasUploadedTarget;
    tryTheLookRef.current = tryTheLook;
  }, [hasUploadedSource, hasUploadedTarget, tryTheLook]);

  const handleVTORequest = useCallback(
    async (src?: BankPhoto) => {
      if (target && source && !tryTheLook) {
        let res;
        if (src) res = await handleShopThatLook(target, src);
        else res = await handleShopThatLook(target, source);
        if (res.data?.shopThatLook) {
          setTryTheLook(res.data.shopThatLook);
          return true;
        } else {
          return false;
        }
      }
    },
    [target, source, tryTheLook],
  );

  const handleUploadToS3 = useCallback(
    async (isSource: boolean): Promise<BankPhoto | null> => {
      if (sourceBlob && target && source) {
        let uploadRes;
        if (!isSource && targetBlob)
          uploadRes = await uploadValidatedImageToStorage(targetBlob, isSource);
        else {
          uploadRes = await uploadValidatedImageToStorage(sourceBlob, isSource);
        }
        if (uploadRes.data?.uploadImageToStorage?.success) {
          const returnImage = {
            s3: {
              Bucket: uploadRes.data.uploadImageToStorage.bucket,
              Key: uploadRes.data.uploadImageToStorage.key,
            },
            url: isSource ? source.url : target.url,
          };

          dispatch(
            isSource
              ? updateCurrentSource(returnImage)
              : updateTarget(returnImage),
          );
          dispatch(
            isSource ? setSourceUploaded(true) : setTargetUploaded(true),
          );
          return returnImage;
        } else {
          return null;
        }
      }
      return null;
    },
    [target, source, targetBlob, sourceBlob, dispatch],
  );

  useEffect(() => {
    const sendRequests = async () => {
      let requests = 0;
      let sourceRes = sourceRef.current;
      if (!tryTheLookRef.current) {
        while (requests <= 5) {
          try {
            if (
              hasUploadedSourceRef.current &&
              hasUploadedTargetRef.current &&
              !(source && sourceRes && sourceRes.s3.Bucket === "")
            ) {
              let res;
              if (sourceRes && sourceRes.s3.Bucket !== "")
                res = await handleVTORequest(sourceRes);
              else res = await handleVTORequest();
              if (res) break;
              else throw "Failed";
            } else {
              if (
                !hasUploadedTargetRef.current ||
                (target && target.url === "")
              ) {
                const res = await handleUploadToS3(false);
                throw res;
              }
              if (
                !hasUploadedSourceRef.current ||
                (source && sourceRef.current?.url === "")
              ) {
                sourceRes = await handleUploadToS3(true);
                throw sourceRes;
              }
              throw "x";
            }
          } catch {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            requests += 1;
          }
        }
        if (requests > 5) {
          returnAfterError();
        }
      }
    };

    dispatch(setProcessing(true));
    sendRequests();
  }, []);

  useEffect(() => {
    const getDiscovery = async () => {
      if (tryTheLook?.image_url) {
        try {
          const comboProductsByPart: Comboes = JSON.parse(
            tryTheLook.products_score,
          );
          const uniqueStrings: Set<string> = new Set();

          for (const finish of Object.values(comboProductsByPart)) {
            for (const comboType of Object.values(finish)) {
              comboType.forEach(
                (productId, index: number) =>
                  index > 1 && uniqueStrings.add(productId as string),
              );
            }
          }

          const productIDs: string[] = Array.from(uniqueStrings);

          let discovery: ProductDiscoveryItem[] = [];
          let discoveryRes;
          for (let i = 0; i <= productIDs.length / 100; i++) {
            discoveryRes = await getCatalog(
              productIDs.slice(i * 100, (i + 1) * 100),
            );
            if (discoveryRes.data?.getProducts.products) {
              const validProducts =
                discoveryRes.data.getProducts.products.filter(
                  (product: any): product is ProductDiscoveryItem =>
                    product !== null,
                );
              discovery = [...discovery, ...validProducts];
            }
          }

          let lookType = LookType.MATTE;
          if (tryTheLook.gloss_label) {
            switch (tryTheLook.gloss_label) {
              case LookType.MATTE:
                lookType = LookType.MATTE;
                break;
              case LookType.GLOSS:
                lookType = LookType.GLOSS;
                break;
              case LookType.SATIN:
                lookType = LookType.SATIN;
                break;
              default:
                lookType = LookType.MATTE;
            }
          }

          let lookEffect = LookEffect.LARGE;
          if (tryTheLook.effect_label) {
            switch (tryTheLook.effect_label) {
              case LookEffect.BOLD:
                lookEffect = LookEffect.BOLD;
                break;
              case LookEffect.LARGE:
                lookEffect = LookEffect.LARGE;
                break;
              case LookEffect.NATURAL:
                lookEffect = LookEffect.NATURAL;
                break;
              default:
                lookEffect = LookEffect.LARGE;
            }
          }

          return new Promise<boolean>((resolve, reject) => {
            const img = new Image();
            img.onload = async () => {
              dispatch(updateCatalog(discovery));
              dispatch(updateLookType(lookType));
              dispatch(updateLookEffect(lookEffect));
              dispatch(updateComboes(comboProductsByPart));
              dispatch(updateTargetCoords(JSON.parse(tryTheLook.target_idx)));
              dispatch(
                updateSourceLipsCoords(JSON.parse(tryTheLook.source_idx).lips),
              );
              dispatch(setProcessing(false));
              dispatch(updateTargetImageCrop(tryTheLook.image_url));
              let [, , , , w, h] = JSON.parse(tryTheLook.source_idx).lips;
              w = w ?? window.innerWidth;
              h = h ?? window.innerHeight * 0.57;
              let xOffset, yOffset;
              switch (lookType) {
                case LookType.MATTE:
                  xOffset = 0;
                  break;
                case LookType.SATIN:
                  xOffset = 1;
                  break;
                case LookType.GLOSS:
                  xOffset = 2;
                  break;
              }

              switch (lookEffect) {
                case LookEffect.LARGE:
                  yOffset = 0;
                  break;
                case LookEffect.BOLD:
                  yOffset = 1;
                  break;
                case LookEffect.NATURAL:
                  yOffset = 2;
                  break;
              }
              const newImageSrc = await drawImageOnCanvas(
                "",
                w,
                h,
                tryTheLook.image_url,
                xOffset,
                yOffset,
              );
              dispatch(updateCroppedResultImage(newImageSrc));
              resolve(true);
            };
            img.onerror = reject; // Reject the promise on an error
            img.src = tryTheLook.image_url;
          });
        } catch (e) {
          console.error(e);
          returnAfterError();
        }
      } else {
        return false;
      }
    };
    const wrapper = async () => {
      try {
        const res = await getDiscovery();
        if (res) dispatch(nextStage()); // This will now wait for the image to load
      } catch (error) {
        returnAfterError();
      }
    };
    wrapper();
  }, [tryTheLook, returnAfterError, dispatch]);

  useEffect(() => {
    // Update the text every two seconds
    let counter = currentMessageIndex;
    const interval = setInterval(() => {
      if (
        currentMessageIndex <
        (t("workingTheMagic", { returnObjects: true }) as string[]).length - 1
      ) {
        setCurrentMessageIndex(counter + 1);
        counter++;
      }
    }, 2800);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  });

  const loadingImg = [Loading0Icon, Loading1Icon, Loading2Icon, Loading3Icon];

  return (
    <div
      className={`gap-4 flex items-center min-h-full w-full flex-col justify-center h-sceen`}
    >
      <div className="flex flex-row px-12">
        <div
          style={{
            backgroundImage: `url(${target?.url})`,
          }}
          className="w-[40vw] h-60 object-cover bg-center bg-no-repeat bg-cover rounded-3xl -rotate-[11deg]"
        />
        <div
          style={{
            backgroundImage: `url(${source?.url})`,
          }}
          className="w-[40vw] h-60 object-cover bg-center bg-no-repeat bg-cover rounded-3xl rotate-[11deg] -ml-6 mt-3"
        />
      </div>
      <div className="relative w-11/12 max-w-[90svw] flex justify-center">
        {loadingImg[currentMessageIndex]("max-w-full")}
      </div>
      <Typography
        size={currentMessageIndex < 3 ? "xl" : "4xl"}
        className="whitespace-break-spaces w-5/6 text-center h-14 line-clamp-2"
      >
        {
          (t("workingTheMagic", { returnObjects: true }) as string[])[
            currentMessageIndex
          ]
        }
      </Typography>
    </div>
  );
};

export default WorkingTheMagic;
