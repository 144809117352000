import React, { ReactNode } from "react";
import { Color, Size, sizesDict } from "../../../Types/common";

export type ButtonProps = {
  action: () => void;
  size?: "small" | "medium" | "large";
  bgColor?: Color | "buttonBg" | "ghost";
  square?: boolean;
  transparent?: boolean;
  rounded?: Size;
  children: ReactNode;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  action,
  size = "medium",
  bgColor = "primary",
  square = false,
  transparent = false,
  className = "",
  rounded = "xl",
  children,
}) => {
  const getSizeClasses = () => {
    switch (size) {
      case "small":
        return "px-2 py-1 text-sm w-16";
      case "large":
        return "px-4 py-2 text-lg w-32";
      case "medium":
      default:
        return "px-3 py-2 text-base w-24";
    }
  };

  const getColorClasses = (): React.ComponentProps<"div">["className"] => {
    if (transparent) {
      return "bg-transparent text-black border border-gray-100 drop-shadow-md";
    }
    switch (bgColor) {
      case "secondary":
        return "bg-secondary text-white";
      case "error":
        return "bg-error text-black";
      case "info":
        return "bg-info text-black";
      case "warning":
        return "bg-warning text-black";
      case "buttonBg":
        return "bg-buttonBg text-white";
      case "ghost":
        return "bg-white !text-buttonBg border border-buttonBg";
      case "primary":
      default:
        return "bg-primary text-black";
    }
  };

  const classes = `${className} flex items-center justify-center ${
    sizesDict[rounded].rounded
  } ${square ? "aspect-square" : ""} ${getSizeClasses()} ${getColorClasses()}`;

  return (
    <button onClick={action} className={classes}>
      {children}
    </button>
  );
};

export default Button;
