import React from "react";

const Loading1Icon = (className: string) => {
  return (
    <svg
      width="807"
      height="109"
      viewBox="0 0 807 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1.5"
        y="80.5"
        width="804"
        height="27"
        rx="13.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <rect
        x="6.5"
        y="85.5"
        width="303"
        height="17"
        rx="8.5"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M239.163 2.5C222.811 2.5 213.892 25.9783 202 34.7826C203.487 40.6522 215.379 70 255.514 70C295.65 70 309.029 39.1848 307.542 34.7826C300.11 27.4457 285.244 2.5 273.352 2.5C259.974 2.5 259.228 8.36957 256.258 9.83696C253.359 8.36957 251.127 2.5 239.163 2.5Z"
        stroke="#B23B3B"
        strokeWidth="5"
      />
      <path
        d="M230.636 37.2727C226.417 39.0441 222.199 40.8155 208.136 37.2727"
        stroke="black"
      />
      <path
        d="M279.727 37.2727C283.946 39.0441 288.165 40.8155 302.227 37.2727"
        stroke="black"
      />
      <path
        d="M255.182 41.3635C247.204 41.3635 240.557 35.2272 228.591 38.2954C232.579 36.7613 239.227 29.0908 244.545 29.0908C249.864 29.0908 253.187 32.159 255.847 32.159C258.506 32.159 260.5 29.0908 267.148 29.0908C273.795 29.0908 276.454 35.2272 281.773 38.2954C272.466 36.7613 263.159 41.3635 255.182 41.3635Z"
        fill="black"
        stroke="#0D0100"
      />
    </svg>
  );
};

export default Loading1Icon;
