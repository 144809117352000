import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode } from "react";
import Typography from "../Common/Typography/Typography";
import Button from "../Common/Button/Button";

export interface DialogueProps {
  title: string;
  content: string;
  closeButtonContent: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  options?: ReactNode[];
  reverseOptions?: boolean;
  className?: string;
  onClose?: () => void;
}

const Dialogue: React.FC<DialogueProps> = ({
  title,
  open,
  setOpen,
  closeButtonContent,
  content,
  options,
  reverseOptions = false,
  className = "",
  onClose,
}: DialogueProps) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999]"
          onClose={() => {
            onClose?.();
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`fixed inset-0 bg-black bg-opacity-25`} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={
                    className +
                    " w-3/4 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  }
                >
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 ltr:text-left rtl:text-right text-buttonBg"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 ltr:text-left rtl:text-right">
                    <Typography color="text-buttonBg whitespace-pre-wrap">
                      {content}
                    </Typography>
                  </div>

                  <div
                    className={`mt-4 flex items-center justify-center ${
                      reverseOptions ? "flex-col-reverse" : "flex-col"
                    } gap-2`}
                  >
                    {options &&
                      options.map((option, index) => (
                        <div
                          role="button"
                          tabIndex={0}
                          key={index}
                          onClick={() => setOpen(false)}
                          className="cursor-pointer w-2/3"
                        >
                          {option}
                        </div>
                      ))}
                    <div className="w-2/3">
                      <Button
                        action={() => setOpen(false)}
                        bgColor="buttonBg"
                        className="hover:bg-gray-800 w-full flex items-center"
                      >
                        <Typography color="white" className="leading-[normal]">
                          {closeButtonContent}
                        </Typography>
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Dialogue;
