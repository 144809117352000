import React from "react";

const Loading0Icon = (className: string) => {
  return (
    <svg
      width="809"
      height="108"
      viewBox="0 0 809 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="3.5"
        y="79.5"
        width="804"
        height="27"
        rx="13.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <rect
        x="8.5"
        y="84.5"
        width="106"
        height="17"
        rx="8.5"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M39.2851 2C22.8796 2 13.9312 25.5556 2 34.3889C3.4914 40.2778 15.4226 69.7222 55.6905 69.7222C95.9584 69.7222 109.381 38.8056 107.89 34.3889C100.433 27.0278 85.5186 2 73.5873 2C60.1647 2 59.4167 7.88889 56.4362 9.36111C53.5278 7.88889 51.2892 2 39.2851 2Z"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M25.5556 34.3892C21.1389 35.8614 16.7222 37.3336 2 34.3892"
        stroke="black"
      />
      <path
        d="M84.4449 34.3892C88.8616 35.8614 93.2783 37.3336 108 34.3892"
        stroke="black"
      />
      <path
        d="M54.9996 37.3334C46.1663 37.3334 38.8052 31.4446 25.5552 34.389C29.9718 32.9168 37.333 25.5557 43.2218 25.5557C49.1107 25.5557 52.7913 28.5001 55.7357 28.5001C58.6802 28.5001 60.8885 25.5557 68.2496 25.5557C75.6107 25.5557 78.5552 31.4446 84.4441 34.389C74.1385 32.9168 63.833 37.3334 54.9996 37.3334Z"
        fill="black"
        stroke="#0D0100"
      />
    </svg>
  );
};

export default Loading0Icon;
