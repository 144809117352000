import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PartOfLook } from "../../../Types/discovery";
import Circle from "../../Common/Circle/Circle";
import Typography from "../../Common/Typography/Typography";
import { CURRENCY_SYMBOL } from "../../../config";
import { useAppSelector } from "../../../app/hooks";
import ProductDialog from "../../ProductDialog/ProductDialog";
import { ProductDiscoveryItem } from "../../../api/types";
import { findProductInDiscovery } from "../../../utils/discoveryHelper";
import ImageWithCheckbox from "./ImageWithCheckbox";
import AddToCartButton from "../AddToCartButton";

export interface MultiCatalogItemProps {
  comboID: string;
  match: number;
  partOfLook: PartOfLook;
  items: string[];
  onProductClick: (id: string) => () => void;
}

// const generateColor = (color_properties: string) => {
//   const splitColor = color_properties.split("-");

//   return `#${splitColor[0]}${Math.round(parseFloat(splitColor[1]) * 255)
//     .toString(16)
//     .toUpperCase()}`;
// };

const getComboSum = (itemsPrices: number[], itemsInUse: number[]) => {
  let sum = 0;
  for (let i = 0; i < itemsInUse.length; i++) {
    sum += itemsPrices[itemsInUse[i]];
  }
  return sum.toFixed(2);
};

const MultiCatalogItem: React.FC<MultiCatalogItemProps> = ({
  items,
  match,
  comboID,
  partOfLook,
  onProductClick,
}) => {
  const { t } = useTranslation();
  const catalog = useAppSelector((state) => state.discovery.catalog);
  const [itemsInUse, setItemsInUse] = useState<number[]>([
    ...items.map((_, i) => i),
  ]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [catalogItems] = useState<ProductDiscoveryItem[]>([
    ...items.map((item) => catalog[findProductInDiscovery(item, catalog)]),
  ]);

  const getPrices = (items: string[]) => {
    return [
      ...items.map((item: string) => {
        const i = catalog[findProductInDiscovery(item, catalog)];
        return i && i.price ? parseFloat(i.price) : 0;
      }),
    ];
  };

  const [prices, setPrices] = useState<number[]>(getPrices(items));
  const itemsInUseIds = itemsInUse.map((item) => {
    return catalog[findProductInDiscovery(items[item] + "", catalog)]
      ?.product_id;
  });

  const onBuyNow = () => setIsDialogOpen(true);

  useEffect(() => {
    setPrices(getPrices(items));
  }, [items, catalog]);

  return (
    <>
      <ProductDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        products={catalogItems}
      />
      <div id={"mistrix-item-" + partOfLook + "-" + comboID}>
        <div className="flex flex-row items-center">
          <div className="drop-shadow-[0_0_6.8px_rgba(0,0,0,0.10)] bg-gray-100 h-36 rounded-2xl w-full flex mb-4 gap-[10%] px-6 flex-row justify-center items-center">
            <div className="flex flex-col items-center justify-center relative rtl:right-2 ltr:left-2">
              <Typography size="2xl">{Math.floor(match * 100)}%</Typography>
              <Typography size="sm">{t("discovery.catalog.match")}</Typography>
              {/* <div className="flex flex-row gap-1 mt-1">
              <Circle
                size="sm"
                id={`info-${comboID}`}
                className="bg-white hover:bg-gray-100 cursor-pointer shadow-md"
              >
                <Typography className="cursor-pointer mt-2" size="sm">
                  i
                </Typography>
              </Circle>
              <Circle
                size="sm"
                id={`video-${comboID}`}
                className="bg-white hover:bg-gray-100 cursor-pointer shadow-md"
              >
                <img
                  src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
                  alt="video"
                />
              </Circle>
            </div> */}
            </div>
            <div className="flex flex-col gap-1 w-fit max-w-[80%]">
              <div className="bg-gray-100 flex h-24 gap-1 flex-row pb-2 overflow-y-clip overflow-x-scroll items-center">
                {items.map((product_id, index) => {
                  const product_index = findProductInDiscovery(
                    product_id,
                    catalog,
                  );
                  if (!catalog[product_index]) return <div />;
                  const id = ["combo", comboID, "item", index, product_id].join(
                    "-",
                  );
                  return (
                    <div key={id} className="relative flex-shrink-0">
                      {index !== items.length - 1 && (
                        <Circle
                          id={"combo-" + comboID + "-plus-" + index}
                          size="sm"
                          className={`absolute top-12 ${
                            index === 0
                              ? items.length > 2
                                ? "ltr:left-[4.4rem] rtl:right-[4.4rem]"
                                : "ltr:left-[4.9rem] rtl:right-[4.9rem]"
                              : items.length > 2
                              ? "ltr:left-[4.1rem] rtl:right-[4.1rem]"
                              : "ltr:left-[4.6rem] rtl:right-[4.6rem]"
                          } bg-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white shadow flex items-center justify-center border-2 z-50`}
                        >
                          <Typography className="h-full leading-[1rem]">
                            +
                          </Typography>
                        </Circle>
                      )}
                      <ImageWithCheckbox
                        index={index}
                        comboID={comboID}
                        itemsInUse={itemsInUse}
                        setItemsInUse={setItemsInUse}
                        itemsLength={items.length}
                        product_index={product_index}
                        onClick={onProductClick(product_id)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-center items-center flex-row gap-4 w-44 mx-auto">
                {/* <Typography
                  className="whitespace-break-spaces pt-1 text-[13px]"
                  size="xs"
                >
                  {t("discovery.catalog.price", {
                    currency: CURRENCY_SYMBOL,
                    price: getComboSum(prices, itemsInUse),
                  })}
                </Typography> */}
                <AddToCartButton
                  onBuyNow={onBuyNow}
                  itemsIds={itemsInUseIds}
                  className="!w-36"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiCatalogItem;
