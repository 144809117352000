import React from "react";
import Circle from "../Common/Circle/Circle";
import Typography from "../Common/Typography/Typography";
import { Direction } from "../../Types/common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setSourceUploaded,
  setSourceValid,
  setStage,
  setTargetUploaded,
  setTargetValid,
} from "../../redux/flow";
import {
  updateCurrentSource,
  updateCurrentSourceBlob,
  updateTarget,
  updateTargetBlob,
} from "../../redux/images";
import i18n from "../../localization/i18n";
import { updateCatalog } from "../../redux/discovery";
import XIcon from "../Common/CustomIcons/XIcon";
import VIcon from "../Common/CustomIcons/VIcon";
import RightIcon from "../Common/CustomIcons/RightIcon";
import LeftIcon from "../Common/CustomIcons/LeftIcon";

interface NavbarProps {
  openExitDialog: () => void;
  direction: Direction;
}

export const Navbar: React.FC<NavbarProps> = ({
  direction,
  openExitDialog,
}: NavbarProps) => {
  const currentActiveStep = useAppSelector((state) => state.flow.currentStage);
  const targetValid = useAppSelector((state) => state.flow.targetValid);
  const sourceValid = useAppSelector((state) => state.flow.sourceValid);
  const hasCatalog =
    useAppSelector((state) => state.discovery.catalog).length !== 0;
  const isProcessing = useAppSelector((state) => state.flow.processing);
  const dispatch = useAppDispatch();

  const getCurrentActiveStep = (step: number) => step === currentActiveStep;

  const backToStart = () => {
    dispatch(setStage(1));
    dispatch(updateTarget(null));
    dispatch(updateTargetBlob(null));
    dispatch(updateCurrentSource(null));
    dispatch(updateCurrentSourceBlob(null));
    dispatch(setTargetValid(false));
    dispatch(setSourceValid(false));
    dispatch(setTargetUploaded(false));
    dispatch(setSourceUploaded(false));
    dispatch(updateCatalog([]));
  };

  const circle = (
    isActiveStep: boolean,
    isDone: boolean,
    number: number,
    index: number,
  ) => {
    return (
      <Circle
        data_testid={`circle${index}`}
        id="mistrix-navbar-circle1"
        size={isActiveStep ? "lg" : "md"}
        filled={isActiveStep || isDone}
        color={"bg-navbar-border"}
        isNavbar
      >
        {isDone ? (
          VIcon(
            `text-navbar-text-active ${isActiveStep ? "w-5 h-5" : "w-3 h-3"}`,
          )
        ) : (
          <Typography
            className={`${isActiveStep ? "leading-9" : "leading-6"} h-full`}
            size={isActiveStep ? "lg" : "xs"}
            color={
              isActiveStep
                ? "text-navbar-text-active"
                : "text-navbar-text-nonactive"
            }
          >
            {number}
          </Typography>
        )}
      </Circle>
    );
  };

  const isLtr = direction === "ltr";

  const circleOne = circle(
    isLtr
      ? getCurrentActiveStep(1)
      : getCurrentActiveStep(3) || getCurrentActiveStep(4),
    targetValid,
    isLtr ? 1 : 3,
    1,
  );
  const circleTwo = circle(getCurrentActiveStep(2), sourceValid, 2, 2);
  const circleThree = circle(
    isLtr
      ? getCurrentActiveStep(3) || getCurrentActiveStep(4)
      : getCurrentActiveStep(1),
    hasCatalog,
    isLtr ? 3 : 1,
    3,
  );

  const openDialog = () => {
    openExitDialog();
  };

  const CloseButton = ({ rtl = true }: { rtl?: boolean }) => {
    return (
      <div
        role="button"
        data-testid="closeButton"
        tabIndex={0}
        onClick={openDialog}
        className={`absolute h-7 cursor-pointer flex items-center ${
          rtl ? "left-4" : "right-4"
        }`}
      >
        {XIcon("text-navbar-border")}
      </div>
    );
  };

  const BackButton = ({ rtl = true }: { rtl?: boolean }) => {
    const Icon = rtl ? RightIcon : LeftIcon;
    return getCurrentActiveStep(1) ? (
      <></>
    ) : (
      <div
        role="button"
        data-testid="backButton"
        tabIndex={0}
        onClick={
          !isProcessing
            ? backToStart
            : () => {
                return;
              }
        }
        className={`absolute h-7 flex items-center ${
          rtl ? "right-4" : "left-4"
        } ${!isProcessing ? "cursor-pointer" : "cursor-default"}`}
      >
        {Icon("text-navbar-border")}
      </div>
    );
  };

  const leftPicture = () => {
    return !isLtr || i18n.dir() === "rtl" ? (
      <CloseButton />
    ) : (
      <BackButton rtl={false} />
    );
  };

  const rightPicture = () => {
    return !isLtr || i18n.dir() === "rtl" ? (
      <BackButton />
    ) : (
      <CloseButton rtl={false} />
    );
  };

  const lineCN = "h-0.5 bg-navbar-border flex-grow";

  return (
    <>
      <div
        style={{
          maxWidth: "inherit",
        }}
        className={`fixed z-50 top-0 w-full h-14 bg-navbar-bg bg-opacity-70 flex items-center justify-center`}
      >
        {leftPicture()}
        <div className="flex justify-between items-center w-1/2">
          {circleOne}
          <div className={"h-0.5 bg-navbar-border flex-grow"} />
          {circleTwo}
          <div className={lineCN} />
          {circleThree}
        </div>
        {rightPicture()}
      </div>
    </>
  );
};

export default Navbar;
