import { API_KEY, APP_DOMAIN, GOOGLE_ANALYTICS_ID } from "../config";
import ReactGA from "react-ga4";

// API requests to the backend
export const makeApiRequest = async (query: string, payload: any) => {
  const API_URL = `${APP_DOMAIN}/api`;

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": API_KEY,
      },
      body: JSON.stringify({ payload: payload, query: query }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Data:", data);
    return data;
  } catch (error: any) {
    console.error("Error:", error.message);
    return { error: error.message };
  }
};

// Initialize analytics collection, generic for different analytics tools
export const initializeAnalytics = () => {
  if (GOOGLE_ANALYTICS_ID) initializeGA();
};

// Collect analytics event, generic for different analytics tools
export const collectAnalytics = (
  event: string,
  parameters: any = null,
  send_to_client: boolean = false,
) => {
  if (GOOGLE_ANALYTICS_ID) collectGA(event, parameters, send_to_client);
};

// Initialize Goggle Analytics
const initializeGA = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
};

// Collect Google Analytics event
const collectGA = (
  event: string,
  parameters: any = null,
  send_to_client: boolean = false,
) => {
  parameters ? ReactGA.event(event, parameters) : ReactGA.event(event);
  if (send_to_client) {
    const payload = parameters
      ? { event: "mistrix_" + event, parameters: parameters }
      : { event: "mistrix_" + event };
    window.parent.postMessage(
      { name: "mistrixAnalytics", type: "GA", payload: payload },
      "*",
    );
  }
};
