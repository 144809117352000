import React from "react";
import { ReactNode } from "react";
import { Size, sizesDict } from "../../../Types/common";

export type TypographyProps = {
  size?: Size;
  color?: string;
  children: ReactNode;
  className?: string;
};

const Typography: React.FC<TypographyProps> = ({
  size = "md",
  color = "text-mainTextColor",
  children,
  className = "",
}: TypographyProps) => {
  const classes = `${sizesDict[size].text} ${color} ${className}`;

  return <span className={classes}>{children}</span>;
};
export default Typography;
