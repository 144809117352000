import React from "react";
import { BankPhoto, SearchPhoto } from "../../Types/images";
import UploadImage from "../UploadImage/UploadImage";
import { useTranslation } from "react-i18next";
import Typography from "../Common/Typography/Typography";

interface GalleryProps {
  images: SearchPhoto[] | BankPhoto[];
  onClickPickImage: (image: SearchPhoto | BankPhoto) => void;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

//  odd:floadt-left odd:ml-2 even:float-right even:mr-2
const TilesGallery: React.FC<GalleryProps> = ({
  images,
  onClickPickImage,
  setHasError,
  setErrorMessage,
}: GalleryProps) => {
  const { t } = useTranslation();

  let inputsCount = 0;

  return images.length > 0 ? (
    <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 h-full w-full">
      {images.map((image: SearchPhoto | BankPhoto, i: number) => {
        const renderInput = i === 2 || (i > 0 && i % 10 === 0);
        inputsCount += renderInput ? 1 : 0;

        return (
          <React.Fragment key={"imagePrev" + i}>
            {renderInput && (
              <UploadImage
                isSource={false}
                sizeTrigger={true}
                errorMessage={t("error.validation.text")}
                setErrorMessage={setErrorMessage}
                setHasError={setHasError}
                text={t("lookSelection.gallery")}
              />
            )}
            <div
              id={"mistrix-gallery-image-" + i}
              role="button"
              tabIndex={0}
              className={`h-60 bg-cover bg-center rounded-3xl ${
                (i + inputsCount) % 2 !== 0 ? "mt-10 -mb-10" : ""
              }`}
              onClick={() => onClickPickImage(image)}
              style={{ backgroundImage: `url(${image.url})` }}
            ></div>
          </React.Fragment>
        );
      })}
    </div>
  ) : (
    <div className="text-center flex items-center w-full">
      <Typography className="text-center w-full">
        {t("generic.loading")}
      </Typography>
    </div>
  );
};

export default TilesGallery;
