import React from "react";
import Typography from "../../Common/Typography/Typography";
import { useAppSelector } from "../../../app/hooks";

interface Props {
  index: number;
  comboID: string;
  product_index: number;
  itemsInUse: number[];
  setItemsInUse: React.Dispatch<React.SetStateAction<number[]>>;
  itemsLength: number;
  onClick: () => void;
}

const ImageWithCheckbox = ({
  index,
  comboID,
  product_index,
  itemsInUse,
  setItemsInUse,
  itemsLength,
  onClick,
}: Props) => {
  const catalog = useAppSelector((state) => state.discovery.catalog);

  const updateItemsInUse = (item: number) => (e: any) => {
    e.stopPropagation();
    if (itemsInUse.indexOf(item) !== -1) {
      const newItems = [...itemsInUse].filter((b) => b !== item);
      setItemsInUse(newItems);
    } else {
      setItemsInUse([...itemsInUse, item]);
    }
  };

  return (
    <div
      className={`${index === 0 ? "rtl:mr-1 ltr:ml-1" : ""} ${
        index === itemsLength - 1 ? "rtl:ml-1 ltr:mr-1" : ""
      } ${
        itemsLength > 2 ? "w-[4.5rem]" : "w-20"
      } mb-2 h-20 z-20 mt-4 bg-white rounded-lg flex flex-col items-center justify-center relative shadow-images cursor-pointer`}
    >
      <input
        type="checkbox"
        id={"combo-" + comboID + "-product-checkbox-" + index}
        className="hidden"
        defaultChecked
        onClick={updateItemsInUse(index)}
      />
      <label
        htmlFor={"combo-" + comboID + "-product-checkbox-" + index}
        className={`absolute text-xs top-0 right-0 w-4 h-4 mr-1 mt-1 border border-gray-300 ${
          itemsInUse.indexOf(index) !== -1 && "bg-gray-300"
        } rounded text-black flex items-center justify-center cursor-pointer`}
      >
        <Typography className="h-full leading-[1rem]" size="lg">
          {itemsInUse.indexOf(index) !== -1 && "✓"}
        </Typography>
      </label>
      <img
        src={catalog[product_index].img_url[0]}
        alt="catalog-product-img"
        data-testid="catalog-product-img"
        className="w-7/12"
        onClick={onClick}
        role="none"
      />
      {/* <div
      style={{
        backgroundColor: generateColor(
          catalog[product_index].color_properties,
        ),
      }}
      className="w-1/3 h-4 rounded-xl border-gray-200 border-[1px] mt-1 mb-1"
    /> */}
    </div>
  );
};

export default ImageWithCheckbox;
