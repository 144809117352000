/* eslint @typescript-eslint/no-explicit-any: 0 */
import { v4 as uuidv4 } from "uuid";
import { singletonHook } from "react-singleton-hook";
import {
  getCurrentSessionID,
  setCurrentSessionID,
} from "./localStorageHandler";
import { useEffect, useState } from "react";
import { INTEGRATION_CLIENT } from "../config";
import { makeApiRequest } from "./apiUtils";

const init = getCurrentSessionID();

const createSession = async (userUuid: string | null) => {
  const payload = { client: INTEGRATION_CLIENT, session_id: userUuid };
  return makeApiRequest("createSessions", payload);
};

const useUserProfileImpl = () => {
  if (!init) {
    localStorage.clear();
  }
  const [userUuid] = useState(!init ? uuidv4() : getCurrentSessionID());

  if (!getCurrentSessionID()) {
    createSession(userUuid);
  }
  useEffect(() => {
    setCurrentSessionID(userUuid);
  }, [userUuid]);

  return userUuid;
};

export const initSession = singletonHook(init, useUserProfileImpl);
