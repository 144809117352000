/* eslint @typescript-eslint/no-explicit-any: 0 */
import {
  MAX_HEIGHT_IMAGE_UPLOAD as MAX_WIDTH,
  MAX_WIDTH_IMAGE_UPLOAD as MAX_HEIGHT,
  INTEGRATION_CLIENT,
  UPLOADS_BUCKET,
} from "../config";
import { getCurrentSessionID } from "./localStorageHandler";
import { SearchPhoto } from "../Types/images";
import { collectAnalytics, makeApiRequest } from "./apiUtils";

export const createImageBlob = async (selectedFile: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = document.createElement("img");
      const originalBlob = e?.target?.result;

      img.onload = async () => {
        const canvas = window.document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        if (ctx) ctx.drawImage(img, 0, 0);

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        if (ctx) ctx.drawImage(img, 0, 0, width, height);

        const resizedBlob = canvas.toDataURL("image/jpeg");

        resolve(resizedBlob);
      };
      if (typeof originalBlob === "string") img.src = originalBlob;
    };

    reader.readAsDataURL(selectedFile);
  });
};

export const uploadValidatedImageToStorage = async (
  image: SearchPhoto | string,
  isSource: boolean,
) => {
  try {
    const sessionId = getCurrentSessionID();
    let dataBlob;
    if (typeof image === "string") {
      dataBlob = image;
    } else dataBlob = image.url;
    const payload = {
      upload_image: dataBlob,
      session_id: sessionId,
      client: INTEGRATION_CLIENT,
      type: isSource ? "source" : "target",
      bucket: UPLOADS_BUCKET,
    };
    const uploadRes = await makeApiRequest("uploadImageToStorage", payload);
    collectAnalytics("image_upload", {
      type: isSource ? "source" : "target",
      bucket: uploadRes.data?.uploadImageToStorage?.bucket,
      key: uploadRes.data?.uploadImageToStorage?.key,
    });
    return uploadRes;
  } catch (error: any) {
    return error.response;
  }
};

export const validateImage = async (imageBlob: string, isSource: boolean) => {
  try {
    const sessionId = getCurrentSessionID();
    const payload = {
      session_id: sessionId,
      image: imageBlob,
      is_source: isSource,
      client: INTEGRATION_CLIENT,
    };
    const validationQuery = await makeApiRequest("validateImages", payload);
    collectAnalytics("image_validation", {
      type: isSource ? "source" : "target",
      is_valid: validationQuery.data?.validateImages?.is_valid,
    });
    return validationQuery;
  } catch (error: any) {
    return error.response;
  }
};

export const cropImage = async (
  imageBlob: string,
  cropArray: (number | null)[],
): Promise<string> => {
  return new Promise<string>((resolve) => {
    const canvas = window.document.createElement("canvas");
    const context = canvas.getContext("2d");
    const imageObj = new Image();
    canvas.width = cropArray[2] ? cropArray[2] : 0;
    canvas.height = cropArray[3] ? cropArray[3] : 0;

    imageObj.onload = async () => {
      const sourceX = cropArray[0] ? cropArray[0] : 0;
      const sourceY = cropArray[1] ? cropArray[1] : 0;
      const sourceWidth = cropArray[2] ? cropArray[2] : 0;
      const sourceHeight = cropArray[3] ? cropArray[3] : 0;
      if (context)
        context.drawImage(
          imageObj,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          0,
          0,
          sourceWidth,
          sourceHeight,
        );
      resolve(canvas.toDataURL("image/jpeg"));
    };

    imageObj.src = imageBlob;
  });
};

export const getImageUrl = async (croppedImage: string): Promise<string> => {
  const res = await fetch(croppedImage);
  const blob: Blob = await res.blob();
  //   blob.name = "target_image";
  return URL.createObjectURL(blob);
};

export const drawImageOnCanvas = (
  imageSrc: string,
  imageXOffset: number,
  imageYOffset: number,
  discoveryImage: string,
  w: number,
  h: number,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(imageSrc + "0");
        return;
      }

      const img = new Image();
      img.crossOrigin = "anonymous";
      // img.src = discoveryImage;
      img.src = `/fetch-image?signedUrl=${encodeURIComponent(discoveryImage)}`;

      img.onload = () => {
        const xOffset = imageXOffset * w;
        const yOffset = imageYOffset * h;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        try {
          ctx.drawImage(img, xOffset, yOffset, w, h, 0, 0, w, h);
        } catch (error) {
          reject(imageSrc + "0");
          return;
        }

        const url = canvas.toDataURL();
        resolve(url);
      };

      img.onerror = () => {
        resolve(imageSrc + "0");
      };
    } catch (error) {
      resolve(imageSrc + "0");
    }
  });
};
