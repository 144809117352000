import React, { useEffect, useState } from "react";

const Loader: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + "/images/loader.gif";
    img.onload = () => setIsLoaded(true);
  }, []);

  return (
    <img
      src={process.env.PUBLIC_URL + "/images/loader.gif"}
      alt="Loading..."
      className={`w-36 ${isLoaded ? "visible" : "invisible"}`}
      onLoad={() => setIsLoaded(true)}
    />
  );
};

export default Loader;
