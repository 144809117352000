/* eslint @typescript-eslint/no-explicit-any: 0 */

import { BankPhoto } from "../Types/images";
import { getCurrentSessionID } from "../utils/localStorageHandler";
import { INTEGRATION_CLIENT, PLATFORM_CLIENT } from "../config";
import { makeApiRequest } from "../utils/apiUtils";

export const handleShopThatLook = async (
  target: BankPhoto,
  source: BankPhoto,
) => {
  try {
    const sessionId = getCurrentSessionID();
    const payload = {
      session_id: sessionId,
      source_img_key: source.s3.Key,
      target_img_key: target.s3.Key,
      source_bucket_name: source.s3.Bucket,
      target_bucket_name: target.s3.Bucket,
      client: PLATFORM_CLIENT,
    };
    return makeApiRequest("shopThatLook", payload);
  } catch (error: any) {
    return error.message;
  }
};

export const getCatalog = async (productIDs: string[]) => {
  try {
    const payload = {
      client: INTEGRATION_CLIENT,
      products_ids: productIDs,
    };
    return makeApiRequest("getProducts", payload);
  } catch (error: any) {
    return error.message;
  }
};
