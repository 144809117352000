import React from "react";
import { useEffect, useState } from "react";
import Typography from "../Components/Common/Typography/Typography";
import getLooksBank from "../data/LooksBank";
import { BankLooks, BankPhoto, SearchPhoto, LooksType } from "../Types/images";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  nextStage,
  setDiscoverMore,
  setIsTargetGallery,
  setTargetUploaded,
  setTargetValid,
} from "../redux/flow";
import Dialogue from "../Components/Dialogue/Dialogue";
import { updateTarget } from "../redux/images";
import Loader from "../Components/Common/Loader/Loader";
import { useTranslation } from "react-i18next";
import Circle from "../Components/Common/Circle/Circle";
import { collectAnalytics } from "../utils/apiUtils";
import TilesGallery from "../Components/Gallery/TilesGallery";
import LookTypeButton from "../Components/SortButtons/LookTypes/LookTypeButton/LookTypeButton";

const UploadLook: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [looks, setLooks] = useState<BankLooks>();
  const [lookType, setLookType] = useState<LooksType>(LooksType.NO_MAKEUP);
  const [looksList, setLooksList] = useState<BankPhoto[]>([]);
  const preview = useAppSelector((state) => state.images.target);
  const isProcessing = useAppSelector((state) => state.flow.processing);
  // const cameraRef = useRef<HTMLInputElement>(null);
  const discoverMore = useAppSelector((state) => state.flow.discoverMore);

  useEffect(() => {
    const [pageLocationGA, pageTitleGA] = ["1", "Step 1 - Upload Look"];
    getLooksBank().then((res) => {
      setLooks(res.data.getLooks);
      setLooksList(res.data.getLooks.no_makeup);
    });

    collectAnalytics("page_view", {
      page_location: pageLocationGA,
      page_title: pageTitleGA,
    });
  }, []);

  const onPickFromGallery = (img: BankPhoto | SearchPhoto) => {
    if (!("referalLink" in img)) {
      collectAnalytics("picked_image_from_gallery", { image: img.url });
      dispatch(updateTarget(img));
      dispatch(setIsTargetGallery(true));
      dispatch(setTargetValid(true));
      dispatch(setTargetUploaded(true));
    }
  };

  useEffect(() => {
    let stageUpdate: ReturnType<typeof setTimeout>;
    if (preview) {
      stageUpdate = setTimeout(() => {
        dispatch(nextStage());
      }, 1500);
    }

    return () => {
      if (stageUpdate) clearTimeout(stageUpdate);
    };
  }, [preview, dispatch]);

  useEffect(() => {
    discoverMore && dispatch(setDiscoverMore(false));
  }, [discoverMore, dispatch]);

  const onLookTypeClick = (type: LooksType) => () => {
    setLookType(type);
    setLooksList(looks?.[type] ?? []);

    const gallery = document.getElementById("tiles-gallery");
    gallery?.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <>
      <Dialogue
        title={t("error.validation.title")}
        setOpen={setHasError}
        content={errorMessage}
        open={hasError}
        closeButtonContent={t("error.validation.button")}
      />
      {preview ? (
        <div
          style={{ backgroundImage: `url(${preview.url})` }}
          className={`w-full bg-cover bg-center h-full`}
        />
      ) : isProcessing ? (
        <div
          className={`w-full h-full min-h-full flex flex-col items-center justify-center gap-4`}
        >
          <Typography>{t("generic.validation")}</Typography>
          <Loader />
        </div>
      ) : (
        <div
          className={`flex relative justify-center pt-20 flex-col w-full min-h-screen items-start px-6 gap-5`}
        >
          <Typography className="font-bold text-mainTextColor" size="4xl">
            {t("lookSelection.title")}
          </Typography>
          <Typography
            size={"lg"}
            className="whitespace-pre-line font-bold text-mainTextColor text-wrap"
          >
            {t("lookSelection.descriptionBig")}
          </Typography>
          <div className="flex flex-row gap-3 w-full overflow-x-scroll overflow-y-hidden pb-3 disable-scrollbars">
            {Object.values(LooksType).map((type: LooksType, i: number) => {
              return (
                <LookTypeButton
                  key={type}
                  className="!min-w-fit"
                  text={
                    (
                      t("lookSelection.lookType", {
                        returnObjects: true,
                      }) as string[]
                    )[i]
                  }
                  onClick={onLookTypeClick(type)}
                  active={type === lookType}
                />
              );
            })}
          </div>
          <div
            id={"tiles-gallery"}
            style={{ height: "calc(100vh - 270px)" }}
            className="flex w-full items-center gap-4 -mt-4 px-2 overflow-scroll h-[calc(100vh_-_350px)]"
          >
            <TilesGallery
              images={looksList}
              onClickPickImage={onPickFromGallery}
              setHasError={setHasError}
              setErrorMessage={setErrorMessage}
            />
            {/* <Circle
                className="border border-gray-100 shadow-button cursor-pointer"
                filled={false}
                id="look-selection-camera"
                size="5xl"
                action={() => cameraRef.current?.click()}
              >
                <div className="flex flex-col items-center gap-2">
                  <img
                    className="w-8"
                    src={process.env.PUBLIC_URL + "/icons/camera-black.svg"}
                    alt="camera"
                  />
                  <input
                    className="hidden"
                    type="file"
                    accept="image/*"
                    onChange={validateAndUpload}
                    capture="user"
                    ref={cameraRef}
                  />
                </div>
              </Circle> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UploadLook;
