import React from "react";
import { ReactNode } from "react";
import { Size, sizesDict } from "../../../Types/common";

export interface CircleProps {
  size: Size;
  color?: string;
  filled?: boolean;
  shadow?: boolean;
  children: ReactNode;
  className?: string;
  id: string;
  data_testid?: string;
  action?: () => void;
  isNavbar?: boolean;
}

const Circle: React.FC<CircleProps> = ({
  children,
  id,
  data_testid,
  size = "sm",
  color = "bg-secondary",
  filled = true,
  shadow = false,
  className = "",
  action,
  isNavbar = false,
}: CircleProps) => {
  const getColorClasses = () => {
    return filled
      ? color
      : isNavbar
      ? "border-2 border-navbar-border bg-transparent"
      : "border-2 border-black bg-transparent";
  };

  return (
    <div
      data-testid={data_testid}
      id={id}
      onClick={action}
      role="none"
      className={`flex items-center ${getColorClasses()} justify-center ${className} ${
        sizesDict[size].height
      } ${sizesDict[size].width} rounded-full ${
        shadow ? "shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Circle;
