export interface BasicImageData {
  url: string;
}

export interface SearchPhoto extends BasicImageData {
  referalLink: string;
}

export interface BankPhoto extends BasicImageData {
  s3: { Bucket: string; Key: string };
}

export enum LooksType {
  NO_MAKEUP = "no_makeup",
  MAKEUP = "makeup",
  MAKEUP_MAKEUP = "makeup_makeup",
}

export type BankLooks = Record<LooksType, BankPhoto[]>;

export type Dimensions = {
  width: number;
  height: number;
};

export type validationResponse = {
  is_valid: boolean;
  crop_idx: [number, number, number, number];
};
