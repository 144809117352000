import { UUID } from "crypto";

const CURRENT_UUID = "current-uuid";
const CURRENT_PRICE_RANGE_KEY = "current-price-range";
const PERSIST_POPUP = "persist-popup";

const localGetter = (key: string): string | null => {
  return localStorage.getItem(key) || null;
};

const localSetter = (key: string, value: string | null) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

const localGetterObject = (key: string): string => {
  return JSON.parse(localGetter(key) || "null");
};

const localSetterObject = (key: string, value: unknown) => {
  localSetter(key, value ? JSON.stringify(value) : null);
};

// const convertBase64ToBlob = (base64Image: string) => {
//     // Split into two parts
//     const parts = base64Image.split(';base64,');

//     // Hold the content type
//     const imageType = parts[0].split(':')[1];

//     // Decode Base64 string
//     const decodedData = window.atob(parts[1]);

//     // Create UNIT8ARRAY of size same as row data length
//     const uInt8Array = new Uint8Array(decodedData.length);

//     // Insert all character code into uInt8Array
//     for (let i = 0; i < decodedData.length; ++i) {
//       uInt8Array[i] = decodedData.charCodeAt(i);
//     }

//     // Return BLOB image after conversion
//     return new Blob([uInt8Array], { type: imageType });
//   }

// export const getCurrentSelfieBlob = () => {
//     const b64_str = localGetter(CURRENT_SELFIE_BLOB_KEY) as string;
//     if (b64_str)
//         return convertBase64ToBlob(b64_str);
//     else
//         return undefined;
// }

// export const setCurrentSelfieBlob = (selfieBlob: Blob | null) => {
//     if(!selfieBlob) {
//         localSetter(CURRENT_SELFIE_BLOB_KEY, null);
//         window.dispatchEvent(new Event('storage'))
//     }
//     else {
//         const reader = new FileReader();
//         reader.readAsDataURL(selfieBlob);
//         reader.onloadend = function() {
//         localSetter(CURRENT_SELFIE_BLOB_KEY, reader.result as string);
//     }
//         window.dispatchEvent(new Event('storage'))
//     }
// }

// export const setPreviousSelfieBlob = (selfieBlob: Blob | null) => {
//     if(!selfieBlob) {
//         localSetter(PREVIOUS_SELFIE_BLOB_KEY, null);
//         window.dispatchEvent(new Event('storage'))
//     }
//     else {
//         const reader = new FileReader();
//         reader.readAsDataURL(selfieBlob);
//         reader.onloadend = function() {
//         localSetter(PREVIOUS_SELFIE_BLOB_KEY, reader.result as string);
//     }
//         window.dispatchEvent(new Event('storage'))
//     }
// }

// export const getCurrentLookBlob = () => {
//     const b64_str = localGetter(CURRENT_LOOK_BLOB_KEY) as string;
//     if (b64_str)
//         return convertBase64ToBlob(b64_str);
//     else
//         return undefined;
// }

// export const setCurrentLookBlob = (lookBlob: Blob | null) => {
//     if(!lookBlob) {
//         localSetter(CURRENT_LOOK_BLOB_KEY, null);
//         window.dispatchEvent(new Event('storage'))
//     }
//     else {
//         const reader = new FileReader();
//         reader.readAsDataURL(lookBlob);
//         reader.onloadend = function() {
//         localSetter(CURRENT_LOOK_BLOB_KEY, reader.result as string); }
//         window.dispatchEvent(new Event('storage'))
//     }
// }

export const getCurrentSessionID = (): UUID | null => {
  return localGetter(CURRENT_UUID) as UUID;
};

export const setCurrentSessionID = (uuid: string | null) => {
  return localSetter(CURRENT_UUID, uuid);
};

export const getDontShowAgain = () => {
  return localGetterObject(PERSIST_POPUP);
};

export const setDontShowAgain = (shouldShow: boolean | null) => {
  localSetterObject(PERSIST_POPUP, shouldShow);
};

export const getCurrentPriceRange = () => {
  return localGetterObject(CURRENT_PRICE_RANGE_KEY);
};

export const onAppClose = () => {
  setDontShowAgain(null);
};
