import { getCurrentSessionID } from "../utils/localStorageHandler";
import { PLATFORM_CLIENT } from "../config";
import { makeApiRequest } from "../utils/apiUtils";

const getLooksBank = async () => {
  try {
    const session_id = getCurrentSessionID();
    const payload = { client: PLATFORM_CLIENT, session_id: session_id };
    return makeApiRequest("getLooks", payload);
  } catch (error: any) {
    return error.message;
  }
};

export default getLooksBank;
