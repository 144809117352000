/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from "react";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import UploadLook from "./pages/UploadLook";
import { Transition } from "@headlessui/react";
import UploadSelfie from "./pages/UploadSelfie";
import Discovery from "./pages/Discovery";
import { useAppSelector } from "./app/hooks";
import i18n from "./localization/i18n";
import ExitDialog from "./Components/ExitDialog/ExitDialog";
import { initSession } from "./utils/sessionInit";
import WorkingTheMagic from "./pages/WorkingTheMagic";
import { preLoadImages } from "./utils/imagePreLoader";
import Hotjar from "@hotjar/browser";
import { THEME } from "./config";
import { initializeAnalytics } from "./utils/apiUtils";

initializeAnalytics();

function App() {
  initSession();

  const currentActiveStep = useAppSelector((state) => state.flow.currentStage);
  const appDirection = i18n.dir();
  const animationDirection =
    appDirection === "rtl" ? "-translate-x-full" : "translate-x-full";
  const [exitDialogOpen, setExitDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    i18n.changeLanguage("en");
    document.body.dir = i18n.dir();
    preLoadImages();
    const siteId = 3710649;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    document.documentElement.setAttribute("class", THEME);
  }, []);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data && event.data === "closeAppOverlay") {
        setExitDialogOpen(true);
      }
    };

    window.addEventListener("message", handleMessage);
    const loaderTimeout = setTimeout(() => setIsLoading(false), 1000);

    return () => {
      window.removeEventListener("message", handleMessage);
      clearTimeout(loaderTimeout);
    };
  }, []);

  const themedImages = {
    "romi-geyor-party": {
      bg1: "bg-romy-geyor-party",
      bg2: "bg-romy-geyor-party",
      loader: "loader-default.png",
    },
    "romi-geyor-spring": {
      bg1: "bg-romy-geyor-spring",
      bg2: "bg-romy-geyor-spring",
      loader: "loader-default.png",
    },
    dorin: { bg1: "bg-dorin", bg2: "bg-dorin", loader: "loader-dorin.png" },
    default: {
      bg1: "bg-default-bright",
      bg2: "bg-default-dark",
      loader: "loader-default.png",
    },
  };

  const stepComponent = {
    1: <UploadLook />,
    2: <UploadSelfie />,
    3: <WorkingTheMagic />,
    4: <Discovery />,
  } as Record<number, ReactNode>;

  return (
    <div
      id="mistrix-shopthatlook-app-container"
      className={`${
        themedImages[THEME][currentActiveStep === 2 ? "bg2" : "bg1"]
      } bg-no-repeat bg-cover bg-center opacity-100 touch-none w-full h-full min-h-full`}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full w-full">
          <img
            src={
              process.env.PUBLIC_URL + "/images/" + themedImages[THEME].loader
            }
            alt="loading"
            className="w-5/6 max-w-[200px]"
          />
        </div>
      ) : (
        <>
          <ExitDialog
            setExitDialogOpen={setExitDialogOpen}
            open={exitDialogOpen}
          />
          <Navbar
            direction={"ltr"}
            openExitDialog={() => setExitDialogOpen(true)}
          />
          {Array(4)
            .fill("")
            .map((_, index) => (
              <Transition
                key={index}
                show={currentActiveStep === index + 1}
                appear
                enter="transition-all ease-in-out duration-250"
                enterFrom={animationDirection}
                enterTo="translate-x-0 min-h-full h-full"
              >
                {stepComponent[index + 1]}
              </Transition>
            ))}
        </>
      )}
      <Footer
        className={currentActiveStep === 4 ? "bg-white bg-opacity-100" : ""}
      />
    </div>
  );
}

export default App;
