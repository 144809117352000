import React from "react";
import { useTranslation } from "react-i18next";
import { Comboes, PartOfLook } from "../../Types/discovery";
import Typography from "../Common/Typography/Typography";
import { useAppDispatch } from "../../app/hooks";
import {
  setDiscoverMore,
  setSourceUploaded,
  setSourceValid,
  setStage,
  setTargetUploaded,
  setTargetValid,
} from "../../redux/flow";
import {
  updateCurrentSource,
  updateCurrentSourceBlob,
  updateTarget,
  updateTargetBlob,
} from "../../redux/images";
import { updateCatalog } from "../../redux/discovery";
import MultiCatalogItem from "./MultiCatalogItem/MultiCatalogItem";
import SingleCatalogItem from "./SingleCatalogItem/SingleCatalogItem";
import LipsIcon from "../Common/CustomIcons/LipsIcon";

export interface CatalogProps {
  partOfLook: PartOfLook | null;
  catalog: Comboes | null;
  onProductClick: (id: string) => () => void;
}

const Catalog: React.FC<CatalogProps> = ({
  partOfLook,
  catalog,
  onProductClick,
}: CatalogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const backToStart = () => {
    dispatch(setStage(1));
    dispatch(updateTarget(null));
    dispatch(updateTargetBlob(null));
    dispatch(updateCurrentSource(null));
    dispatch(updateCurrentSourceBlob(null));
    dispatch(setTargetValid(false));
    dispatch(setSourceValid(false));
    dispatch(setTargetUploaded(false));
    dispatch(setSourceUploaded(false));
    dispatch(updateCatalog([]));
    dispatch(setDiscoverMore(true));
  };

  return (
    <>
      {catalog && partOfLook && Object.keys(catalog[partOfLook]).length > 0 ? (
        <div className="w-11/12">
          {Object.keys(catalog[partOfLook]).map((combo_id) => {
            if (catalog[partOfLook][combo_id].length > 3) {
              const itemsArr: string[] = (
                catalog[partOfLook][combo_id] as (string | number)[]
              ).filter(
                (i, index): i is string => typeof i === "string" && index >= 2,
              );
              return (
                <MultiCatalogItem
                  partOfLook={partOfLook}
                  comboID={combo_id}
                  match={catalog[partOfLook][combo_id][0]}
                  key={combo_id + "-" + partOfLook}
                  items={itemsArr}
                  onProductClick={onProductClick}
                />
              );
            } else {
              return (
                <SingleCatalogItem
                  match={catalog[partOfLook][combo_id][0]}
                  key={combo_id + "-" + partOfLook}
                  item_id={catalog[partOfLook][combo_id][2]}
                  onAddToCart={(productID: string) => {
                    return productID;
                  }}
                  onProductClick={onProductClick}
                />
              );
            }
          })}
        </div>
      ) : (
        <div className="text-center pb-4 mx-1 flex items-center justify-center">
          <Typography className="">
            {t("discovery.catalog.noMatches")}
          </Typography>
        </div>
      )}
      <div
        onClick={backToStart}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            backToStart();
          }
        }}
        className="flex cursor-pointer flex-row justify-center py-2 items-center hover:opacity-70 bg-white border border-buttonBg rounded-full h-12 w-full mb-4 gap-4"
        role="button"
        tabIndex={0}
      >
        {LipsIcon("text-buttonBg w-6 h-6")}
        <Typography className="mt-2 text-buttonBg" size="md">
          {t("discovery.catalog.discoverMore")}
        </Typography>
      </div>
    </>
  );
};

export default Catalog;
