import { Dialog, Transition } from "@headlessui/react";
import { ProductDiscoveryItem } from "../../api/types";
import React, { Fragment } from "react";
import Typography from "../Common/Typography/Typography";
import Button from "../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { checkProduct } from "../../utils/discoveryHelper";

interface ProductDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  products: ProductDiscoveryItem[];
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  open,
  setOpen,
  products,
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    // e?.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`fixed inset-0 bg-black bg-opacity-25 h-full`} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-[66%] transform rounded-2xl bg-white px-6 py-2 items-center justify-center shadow-xl transition-all h-40 overflow-hidden">
                  <div
                    role="button"
                    tabIndex={0}
                    className="w-4 h-4 absolute top-2 left-2 cursor-pointer"
                    onClick={onClose}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/icons/icon-x.svg"}
                      alt="close"
                    />
                  </div>
                  <div className="flex flex-row overflow-x-scroll overflow-y-hidden gap-3 p-0 h-full items-center">
                    {products.map((product, index) => {
                      return (
                        <div
                          key={product?.product_id + "-" + index}
                          className="flex flex-col justify-center items-center gap-2"
                        >
                          <img
                            src={product?.img_url[0]}
                            alt=""
                            className="h-24 object-contain"
                          />
                          <Button
                            className="hover:opacity-70"
                            size="small"
                            action={checkProduct(product)}
                            bgColor="buttonBg"
                          >
                            <Typography
                              size="xs"
                              className="leading-[normal] py-0.5 text-white"
                            >
                              {t("discovery.catalog.view")}
                            </Typography>
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ProductDialog;
