/* eslint jsx-a11y/media-has-caption: 0 */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const src =
  "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4";

interface VideoPlayerProps {
  isOpen: boolean;
  setOpen: (toSet: boolean) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ isOpen, setOpen }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[9999]"
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`fixed inset-0 bg-black bg-opacity-25 h-full`} />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-11/12 transform overflow-hidden rounded-2xl bg-white p-8 flex flex-row gap-2 items-center justify-center shadow-xl transition-all h-40">
                  <div
                    role="button"
                    tabIndex={0}
                    className="w-4 h-4 absolute top-2 left-2 cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/icons/icon-x.svg"}
                      alt="close"
                    />
                  </div>
                  <video controls width="100%" className="rounded-xl">
                    <source src={src} type="video/mp4" />
                    Sorry, your browser does not support embedded videos.
                  </video>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default VideoPlayer;
