import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
// import { LookEffect, LookType } from "../../Types/discovery";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { drawImageOnCanvas } from "../../utils/imageDataHandler";

interface ZoomImageProps {
  showBefore: boolean;
  // lookType: LookType;
  // lookEffect: LookEffect;
  updateImage: (image: File) => void;
  updateUrl: (url: string) => void;
}

const ZoomImage: React.FC<ZoomImageProps> = ({
  showBefore,
  // lookType,
  // lookEffect,
  updateImage,
  updateUrl,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const discoveryImage = useAppSelector(
    (state) => state.discovery.imageTargetCropped,
  );
  const [, , , , w, h] = useAppSelector(
    (state) => state.discovery.sourceLipsCoords,
  );
  const sourceImage = useAppSelector(
    (state) => state.images.currentSource?.url,
  );

  const initialCrop = useAppSelector(
    (state) => state.images.croppedResultImage,
  );
  const bodyElement = document.getElementById(
    "mistrix-shopthatlook-app-container",
  );
  let pageWidth: number;
  if (bodyElement) {
    pageWidth = bodyElement.getBoundingClientRect().width;
  } else {
    pageWidth = window.innerWidth;
  }

  const [imageXOffset, setImageXOffset] = useState<number>(0);
  const [imageYOffset, setImageYOffset] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState<string>(initialCrop);

  useEffect(() => {
    const updateImageSrc = async () => {
      const newW = w ?? window.innerWidth;
      const newH = h ?? window.innerHeight * 0.57;
      const url = await drawImageOnCanvas(
        imageSrc,
        imageXOffset,
        imageYOffset,
        discoveryImage,
        newW,
        newH,
      );
      setImageSrc(url);
      updateUrl(url);
      const arr = url.split(",");
      const matches = arr[0].match(/:(.*?);/);
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      if (matches && matches[1])
        updateImage(new File([u8arr], "look.png", { type: matches[1] }));
    };

    updateImageSrc();
  }, [
    imageSrc,
    imageXOffset,
    imageYOffset,
    discoveryImage,
    pageWidth,
    w,
    h,
    updateImage,
    updateUrl,
  ]);

  // useEffect(() => {
  //   switch (lookType) {
  //     case LookType.GLOSS:
  //       setImageXOffset(0);
  //       break;
  //     case LookType.SATIN:
  //       setImageXOffset(1);
  //       break;
  //     case LookType.MATTE:
  //       setImageXOffset(2);
  //       break;
  //   }
  // }, [lookType]);

  // useEffect(() => {
  //   switch (lookEffect) {
  //     case LookEffect.LARGE:
  //       setImageYOffset(0);
  //       break;
  //     case LookEffect.BOLD:
  //       setImageYOffset(1);
  //       break;
  //     case LookEffect.NATURAL:
  //       setImageYOffset(2);
  //       break;
  //   }
  // }, [lookEffect]);

  return (
    <TransformWrapper minScale={1}>
      <TransformComponent
        wrapperStyle={{
          height: "100%",
          width: "100%",
        }}
        contentStyle={{
          height: "100%",
          width: "100%",
        }}
      >
        {showBefore ? (
          <div
            className="bg-center bg-cover w-full h-full relative"
            style={{ backgroundImage: `url(${sourceImage})` }}
          />
        ) : (
          imageSrc.length < 100 || (
            <img
              ref={imgRef}
              src={imageSrc}
              alt=""
              className={`${"w-full h-full"} relative object-cover border-none`}
            />
          )
        )}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomImage;
