import React, { useEffect, useState, MouseEvent, TouchEvent } from "react";
import BottomPanel from "../Components/BottomPanel/BottomPanel";
import Catalog from "../Components/Catalog/Catalog";
import SortButtons from "../Components/SortButtons/SortButtons";
import ZoomImage from "../Components/ZoomImage/ZoomImage";

import {
  ComboProductArray,
  Comboes,
  PartOfLook,
  SortMethods,
} from "../Types/discovery";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { updateComboes, updatePartOfLook } from "../redux/discovery";
// import ShareMenu from "../Components/ShareMenu/ShareMenu";
import VideoPlayer from "../Components/VideoPlayer/VideoPlayer";
import { collectAnalytics } from "../utils/apiUtils";
import ProductView from "../Components/Catalog/ProductView/ProductView";

const Discovery: React.FC = () => {
  const combos = useAppSelector((state) => state.discovery.comboData);
  const isGalleryImage = useAppSelector((state) => state.flow.isTargetGallery);
  const sourceImage = useAppSelector(
    (state) => state.images.currentSource?.url,
  );
  const dispatch = useAppDispatch();
  const targetImage = useAppSelector((state) => state.images.target?.url);
  // const initialLookType = useAppSelector(
  //   (state) => state.discovery.initialType,
  // );
  // const initialLookEffect = useAppSelector(
  //   (state) => state.discovery.initialEffect,
  // );
  const currentPartOfLook = useAppSelector(
    (state) => state.discovery.initialPartOfLook,
  );
  // const [currentLookType, setCurrentLookType] =
  //   useState<LookType>(initialLookType);
  // const [currentLookEffect, setCurrentLookEffect] =
  //   useState<LookEffect>(initialLookEffect);

  const targetCoords = useAppSelector((state) => state.discovery.targetCoords);
  const [x1, y1, w, h, x2, y2] = React.useMemo(
    () => (currentPartOfLook ? targetCoords[currentPartOfLook] : []),
    [currentPartOfLook],
  );
  const [currentCombos, setCurrentCombos] = useState<Comboes | null>(combos);
  const [showBefore, setShowBefore] = useState<boolean>(false);
  const [showInspo, setShowInspo] = useState<boolean>(false);
  const [imageCrop, setImageCrop] = useState<string>("");
  const [imageData, setImageData] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [shareAvailable, setShareAvailable] = useState<boolean>(true);
  const [videoPopupOpen, setVideoPopupOpen] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [productViewId, setProductViewId] = useState<string | null>(null);

  useEffect(() => {
    const [pageLocationGA, pageTitleGA] = ["4", "Step 4 - Discovery"];

    collectAnalytics("page_view", {
      page_location: pageLocationGA,
      page_title: pageTitleGA,
    });
  }, []);

  useEffect(() => {
    if (!combos) {
      dispatch(updatePartOfLook(PartOfLook.TOTAL_LOOK));
    } else if (!currentPartOfLook) {
      const partsWithCombos = Object.values(PartOfLook)?.filter(
        (part) => Object.keys(combos[part]).length !== 0,
      );
      dispatch(updatePartOfLook(partsWithCombos[0]));
    }
  }, []);

  const updateCatalogSortMethod = (
    method: SortMethods,
    partOfLook: PartOfLook | null,
  ) => {
    if (combos && partOfLook) {
      const currentCatalog = Object.values(combos[partOfLook]);
      const sorted: { [key: number]: ComboProductArray } = {};
      if (method === SortMethods.SIMILARITY) {
        currentCatalog.sort((a, b) => b[0] - a[0]);
        for (let i = 0; i < currentCatalog.length; i++)
          sorted[i] = currentCatalog[i];
      } else {
        if (method === SortMethods.PRICE_DECENDING)
          currentCatalog.sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]));
        else currentCatalog.sort((a, b) => parseFloat(a[1]) - parseFloat(b[1]));
        for (let i = 0; i < currentCatalog.length; i++)
          sorted[i] = currentCatalog[i];
      }
      const newCombos = { ...combos, [partOfLook]: sorted };
      dispatch(updateComboes(newCombos));
      setCurrentCombos(newCombos);
    }
  };

  useEffect(() => {
    const drawImageOnCanvas = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      const newW = w ? w : 40;
      const newH = h ? h : 40;
      const widthScale = 40 / newW;
      const heightScale = 40 / newH;
      const scale = Math.min(widthScale, heightScale);
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = targetImage ?? "";
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas first

        ctx.drawImage(
          img,
          x1 ?? 0,
          y1 ?? 0,
          newW,
          newH,
          0,
          0,
          newW * scale,
          newH * scale,
        );

        setImageCrop(canvas.toDataURL());
      };
    };
    if (!isGalleryImage) drawImageOnCanvas();
  });

  async function shareImage() {
    if (navigator.share && imageData) {
      try {
        setShareAvailable(true);
        await navigator.share({
          files: [imageData],
          text: "How do I look?",
        });
      } catch (err) {
        setShareAvailable(false);
      }
    } else {
      setShareAvailable(false);
    }
  }

  const onProductClick = (id: string) => () => {
    setProductViewId(id);
    setIsZoomed(true);
  };

  const onBackClick = (e: any) => {
    e.stopPropagation();
    setProductViewId(null);
    setIsZoomed(true);
  };

  const bottomPanelTopContent = productViewId ? (
    <img
      src={process.env.PUBLIC_URL + "/icons/arrow-left.svg"}
      alt="back"
      className="cursor-pointer"
      onClick={onBackClick}
      role="none"
    />
  ) : (
    <SortButtons
      isZoomed={isZoomed}
      onChangeFilterMethod={updateCatalogSortMethod}
    />
  );

  const scaleFactor = Math.min(40 / (w ?? 1), 40 / (h ?? 1));
  const backgroundPos = isGalleryImage
    ? `-${scaleFactor * (x1 ?? 0)}px -${scaleFactor * (y1 ?? 0)}px`
    : "";
  const backgroundSize = isGalleryImage
    ? `${scaleFactor * (x2 ?? 1)}px ${scaleFactor * (y2 ?? 1)}px`
    : "";

  const showBeforeImage = (
    e: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setShowBefore(true);
  };
  const showAfterImage = () => setShowBefore(false);

  const showInspoImage = (
    e: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setShowInspo(true);
  };
  const hideInspoImage = () => setShowInspo(false);

  const onCloseBottomPanel = () => setProductViewId(null);

  return (
    <div className={`h-full min-h-full w-full overflow-y-clip max-w-[inherit]`}>
      <VideoPlayer setOpen={setVideoPopupOpen} isOpen={videoPopupOpen} />
      <div className="w-full h-[65%] relative flex flex-col items-center justify-center">
        <div
          className="bg-center bg-cover blur-md w-full h-full absolute top-0 left-0 z-0 no-select"
          style={{ backgroundImage: `url(${sourceImage})` }}
        />

        {!isZoomed && (
          <div className="absolute bottom-14 right-2 z-10 gap-5 flex flex-col content-center items-center">
            <div
              role="button"
              tabIndex={-1}
              onTouchStart={showBeforeImage}
              onTouchEnd={showAfterImage}
              onMouseDown={showBeforeImage}
              onMouseUp={showAfterImage}
              onMouseLeave={showAfterImage}
              onContextMenu={(e) => e.preventDefault()}
              className="w-7 bg-contain h-6 bg-no-repeat no-select"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/icons/before-after.svg"
                })`,
              }}
            />
            <div
              role="button"
              tabIndex={-1}
              onTouchStart={showInspoImage}
              onTouchEnd={hideInspoImage}
              onMouseDown={showInspoImage}
              onMouseUp={hideInspoImage}
              onMouseLeave={hideInspoImage}
              onContextMenu={(e) => e.preventDefault()}
              className="aspect-square w-[40px] rounded-full bg-no-repeat no-select"
              style={{
                backgroundImage: `url(${
                  isGalleryImage ? targetImage : imageCrop
                })`,
                backgroundPosition: backgroundPos,
                backgroundSize: backgroundSize,
              }}
            />
            {/* <div
            role="button"
            tabIndex={-1}
            onClick={() => setVideoPopupOpen(true)}
            className="w-7 ml-1 select-none touch-none"
          >
            <img
              style={{ WebkitTouchCallout: "none" }}
              src={process.env.PUBLIC_URL + "/icons/play-icon.svg"}
              alt="video"
              className="w-full invert"
            />
          </div> */}
            {/* {typeof navigator.share !== "undefined" && (
              <ShareMenu
                shouldShow={!shareAvailable}
                imageUrl={imageUrl}
                dropdownButton={
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={shareImage}
                    className="w-7 ml-1 select-none touch-none"
                  >
                    <img
                      style={{ WebkitTouchCallout: "none" }}
                      src={process.env.PUBLIC_URL + "/icons/share.svg"}
                      alt="share"
                      className="w-full"
                    />
                  </div>
                }
              />
            )} */}
          </div>
        )}
        {showInspo && (
          <div
            style={{
              backgroundImage: `url(${targetImage})`,
            }}
            className="w-[80vw] h-[80vw] max-w-[500px] max-h-[500px] mt-[20px] sm:mt-[200px] object-cover bg-no-repeat bg-cover rounded-full z-50 absolute no-select"
          />
        )}
        <ZoomImage
          // lookEffect={currentLookEffect}
          // lookType={currentLookType}
          showBefore={showBefore}
          updateImage={setImageData}
          updateUrl={setImageUrl}
        />
      </div>
      <BottomPanel
        catalog
        topContent={bottomPanelTopContent}
        isDraggable={true}
        setExportedZoom={setIsZoomed}
        onClose={onCloseBottomPanel}
        shouldZoom={isZoomed}
      >
        {productViewId ? (
          <ProductView item_id={productViewId} />
        ) : (
          <Catalog
            catalog={currentCombos}
            partOfLook={currentPartOfLook}
            onProductClick={onProductClick}
          />
        )}
      </BottomPanel>
    </div>
  );
};

export default Discovery;
