import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { appStage } from "../Types/common";

export interface FlowState {
  currentStage: appStage;
  targetValid: boolean;
  sourceValid: boolean;
  sourceUploaded: boolean;
  targetUploaded: boolean;
  processing: boolean;
  wtmFailed: boolean;
  isTargetGallery: boolean;
  isDesktop: boolean;
  discoverMore: boolean;
}

const initialState: FlowState = {
  currentStage: 1,
  targetValid: false,
  sourceValid: false,
  sourceUploaded: false,
  targetUploaded: false,
  processing: false,
  wtmFailed: false,
  isTargetGallery: false,
  isDesktop: false,
  discoverMore: false,
};

export const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    nextStage: (state) => {
      if (state.currentStage < 4) {
        state.currentStage += 1;
      }
    },
    previousStage: (state) => {
      if (state.currentStage > 1) {
        state.currentStage -= 1;
      }
    },
    setStage: (state, action: PayloadAction<appStage>) => {
      state.currentStage = action.payload;
    },
    setTargetValid: (state, action: PayloadAction<boolean>) => {
      state.targetValid = action.payload;
    },
    setSourceValid: (state, action: PayloadAction<boolean>) => {
      state.sourceValid = action.payload;
    },
    setTargetUploaded: (state, action: PayloadAction<boolean>) => {
      state.targetUploaded = action.payload;
    },
    setSourceUploaded: (state, action: PayloadAction<boolean>) => {
      state.sourceUploaded = action.payload;
    },
    setProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    setFailed: (state, action: PayloadAction<boolean>) => {
      state.wtmFailed = action.payload;
    },
    setIsTargetGallery: (state, action: PayloadAction<boolean>) => {
      state.isTargetGallery = action.payload;
    },
    setIsDesktop: (state, action: PayloadAction<boolean>) => {
      state.isDesktop = action.payload;
    },
    setDiscoverMore: (state, action: PayloadAction<boolean>) => {
      state.discoverMore = action.payload;
    },
  },
});

export const {
  nextStage,
  previousStage,
  setStage,
  setSourceUploaded,
  setSourceValid,
  setTargetUploaded,
  setTargetValid,
  setProcessing,
  setFailed,
  setIsTargetGallery,
  setIsDesktop,
  setDiscoverMore,
} = flowSlice.actions;

export default flowSlice.reducer;
