import { ProductDiscoveryItem } from "../api/types";

export type ProductScore = {
  [key: string]: [number, boolean];
};

export type minProduct = {
  product_id: number;
  price: number;
  img_url: string[];
  R: number;
  G: number;
  B: number;
  Alpha: number;
};

export type ComboProductArray = [
  number,
  string,
  string,
  string?,
  string?,
  string?,
];

export type ComboTypeObject = {
  [key: string]: ComboProductArray;
};

export type Comboes = {
  [key in PartOfLook]: ComboTypeObject;
};

export type ComboProduct = {
  combo_id: number;
  match: number;
  products: minProduct[];
};

export enum SortMethods {
  SIMILARITY,
  PRICE_ASCENDING,
  PRICE_DECENDING,
}

export type Product = {
  match: number;
  is_gloss: boolean;
  product: ProductDiscoveryItem;
};

export enum LookEffect {
  NATURAL = "natural",
  LARGE = "expand",
  BOLD = "emphasize",
}

export enum LookType {
  MATTE = "matte",
  SATIN = "satin",
  GLOSS = "gloss",
}

export enum PartOfLook {
  TOTAL_LOOK = "total_look",
  FACE = "face",
  EYES = "eyes",
  LIPS = "lips",
}
