import React from "react";
import Typography from "../Common/Typography/Typography";

const Footer = ({ className }: { className?: string }) => {
  return (
    <div
      id="mistrix-footer"
      className={`touch-none font-thin fixed py-2 gap-2 bottom-0 h-7 z-50 w-full flex ltr:flex-row rtl:flex-row-reverse items-center justify-center bg-navbar-bg bg-opacity-70 ${className}`}
    >
      <Typography size="sm" className="leading-[normal]">
        Powered by MISTRIX
      </Typography>
      <img className="w-12 pb-0.5" src={"/logo-black.png"} alt="logo" />
    </div>
  );
};

export default Footer;
