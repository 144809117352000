import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BankPhoto, Dimensions } from "../Types/images";
import { RootState } from "../app/store";

export interface ImagesState {
  targetBlob: string | null;
  target: BankPhoto | null;
  targetDimensions: Dimensions | null;
  currentSourceBlob: string | null;
  currentSource: BankPhoto | null;
  previousSourceBlob: string | null;
  previousSource: BankPhoto | null;
  croppedResultImage: string;
}

const initialState: ImagesState = {
  targetBlob: null,
  target: null,
  targetDimensions: null,
  currentSourceBlob: null,
  currentSource: null,
  previousSourceBlob: null,
  previousSource: null,
  croppedResultImage: "",
};

export const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    updateTargetBlob: (state, action: PayloadAction<string | null>) => {
      state.targetBlob = action.payload;
    },
    updateTarget: (state, action: PayloadAction<BankPhoto | null>) => {
      state.target = action.payload;
    },
    updateTargetDimensions: (
      state,
      action: PayloadAction<Dimensions | null>,
    ) => {
      state.targetDimensions = action.payload;
    },
    updateCurrentSourceBlob: (state, action: PayloadAction<string | null>) => {
      state.currentSourceBlob = action.payload;
    },
    updateCurrentSource: (state, action: PayloadAction<BankPhoto | null>) => {
      state.currentSource = action.payload;
    },
    updatePreviousSourceBlob: (state, action: PayloadAction<string | null>) => {
      state.previousSourceBlob = action.payload;
    },
    updatePreviousSource: (state, action: PayloadAction<BankPhoto | null>) => {
      state.previousSource = action.payload;
    },
    updateCroppedResultImage: (state, action: PayloadAction<string>) => {
      state.croppedResultImage = action.payload;
    },
  },
});

export const {
  updateCurrentSource,
  updateCurrentSourceBlob,
  updatePreviousSource,
  updatePreviousSourceBlob,
  updateTarget,
  updateTargetBlob,
  updateTargetDimensions,
  updateCroppedResultImage,
} = imagesSlice.actions;

export const selectTargetBlob = (state: RootState) => state.images.targetBlob;
export const selectTarget = (state: RootState) => state.images.target;
export const selectTargetDimensions = (state: RootState) =>
  state.images.targetDimensions;
export const selectCurrentSourceBlob = (state: RootState) =>
  state.images.currentSourceBlob;
export const selectCurrentSource = (state: RootState) =>
  state.images.currentSource;
export const selectPreviousSourceBlob = (state: RootState) =>
  state.images.previousSourceBlob;
export const selectPreviousSource = (state: RootState) =>
  state.images.previousSource;
export const selectCroppedResultImage = (state: RootState) =>
  state.images.croppedResultImage;

export default imagesSlice.reducer;
