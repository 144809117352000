export type Color = "primary" | "secondary" | "error" | "warning" | "info";
export type Size =
  | "2xs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl";
export type Direction = "ltr" | "rtl";

type Properties = {
  text: string;
  width: string;
  height: string;
  rounded?: string;
};

export const sizesDict: { [key in Size]: Properties } = {
  "2xs": {
    text: "text-[0.5rem]",
    width: "w-1",
    height: "h-1",
    rounded: "rounded-xs",
  },
  xs: {
    text: "text-xs",
    width: "w-2",
    height: "h-2",
    rounded: "rounded-xs",
  },
  sm: {
    text: "text-sm",
    width: "w-4",
    height: "h-4",
    rounded: "rounded-sm",
  },
  md: {
    text: "text-md",
    width: "w-6",
    height: "h-6",
    rounded: "rounded-md",
  },
  lg: {
    text: "text-lg",
    width: "w-8",
    height: "h-8",
    rounded: "rounded-lg",
  },
  xl: {
    text: "text-xl",
    width: "w-10",
    height: "h-10",
    rounded: "rounded-xl",
  },
  "2xl": {
    text: "text-2xl",
    width: "w-12",
    height: "h-12",
    rounded: "rounded-2xl",
  },
  "3xl": {
    text: "text-3xl",
    width: "w-14",
    height: "h-14",
    rounded: "rounded-3xl",
  },
  "4xl": {
    text: "text-4xl",
    width: "w-16",
    height: "h-16",
  },
  "5xl": {
    text: "text-5xl",
    width: "w-20",
    height: "h-20",
  },
  "6xl": {
    text: "text-6xl",
    width: "w-24",
    height: "h-24",
  },
  "7xl": {
    text: "text-7xl",
    width: "w-28",
    height: "h-28",
  },
  "8xl": {
    text: "text-8xl",
    width: "w-32",
    height: "h-32",
  },
  "9xl": {
    text: "text-9xl",
    width: "w-36",
    height: "h-36",
  },
};

export type appStage = 1 | 2 | 3 | 4;
