import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import Circle from "../../Common/Circle/Circle";
import { SortMethods } from "../../../Types/discovery";
import Typography from "../../Common/Typography/Typography";
import SortIcon from "../../Common/CustomIcons/SortIcon";
import VIcon from "../../Common/CustomIcons/VIcon";

interface DropdownProps {
  onChangeFilter: (filter: SortMethods) => void;
}

const SortDropdown: React.FC<DropdownProps> = ({ onChangeFilter }) => {
  const [currentFilter, setCurrentFilter] = useState(0);
  const { t } = useTranslation();

  const changeFilter = (filter: SortMethods) => (e: React.MouseEvent) => {
    e?.stopPropagation();
    setCurrentFilter(filter);
    onChangeFilter(filter);
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button onClick={(e: React.MouseEvent) => e?.stopPropagation()}>
            <Circle
              className={`shadow-lg relative ${!open ? "z-10" : ""}`}
              color="bg-white"
              size="lg"
              id="sort-catalog"
            >
              {SortIcon("text-buttonBg")}
            </Circle>
          </Menu.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-75"
            enterFrom="transform opacity-0 scale-95 absolute z-50"
            enterTo="transform opacity-100 scale-100 absolute z-50"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100 absolute z-50"
            leaveTo="transform opacity-0 scale-95 absolute z-50"
          >
            <Menu.Items className="bg-white rounded-lg py-2 gap-1 absolute top-0 ltr:-right-8 rtl:-left-8 z-50 w-48 px-2 text-sm flex flex-col font-medium text-gray-200">
              <div className="pb-2 flex flex-row items-center">
                <Typography className="leading-[normal] py-0.5" size="md">
                  {t("discovery.sort.by")}
                </Typography>
                {SortIcon("rtl:mr-auto ltr:ml-auto text-buttonBg")}
              </div>
              {Object.keys(SortMethods)
                .filter((key) => isNaN(Number(key)))
                .map((key: string, index: number) => {
                  return (
                    <Menu.Item key={key} as={Fragment}>
                      {({ active }) => (
                        <div
                          role="button"
                          tabIndex={0}
                          className={`${
                            active ? "bg-gray-100" : ""
                          } cursor-pointer flex flex-row gap-2`}
                          onClick={changeFilter(index)}
                        >
                          {currentFilter === index &&
                            VIcon("text-buttonBg h-auto")}
                          <Typography
                            className={`py-0.5 mt-1 leading-[normal] ${
                              currentFilter === index ? "font-bold" : ""
                            }`}
                          >
                            {
                              (
                                t("discovery.sort.sortingMethods", {
                                  returnObjects: true,
                                }) as string[]
                              )[index]
                            }
                          </Typography>
                        </div>
                      )}
                    </Menu.Item>
                  );
                })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SortDropdown;
