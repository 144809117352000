import React, { useEffect, useState } from "react";
import Typography from "../Components/Common/Typography/Typography";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { nextStage, setFailed, setSourceValid } from "../redux/flow";
import { updateCurrentSource, updateCurrentSourceBlob } from "../redux/images";
import Dialogue from "../Components/Dialogue/Dialogue";
import Loader from "../Components/Common/Loader/Loader";
import { useTranslation } from "react-i18next";
import Circle from "../Components/Common/Circle/Circle";
import { collectAnalytics } from "../utils/apiUtils";
import UploadImage from "../Components/UploadImage/UploadImage";

const UploadSelfie: React.FC = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(t("error.oops"));

  const preview = useAppSelector((state) => state.images.currentSource);
  const isProcessing = useAppSelector((state) => state.flow.processing);
  const previousSelfie = useAppSelector((state) => state.images.previousSource);
  const previousSelfieBlob = useAppSelector(
    (state) => state.images.previousSourceBlob,
  );
  const isWtmError = useAppSelector((state) => state.flow.wtmFailed);
  // const cameraRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const targetImage = useAppSelector((state) => state.images.target?.url);

  const bodyElement = document.getElementById(
    "mistrix-shopthatlook-app-container",
  );
  const sizeTrigger =
    (bodyElement
      ? bodyElement.getBoundingClientRect().height
      : window.innerHeight) > 520;
  const buttonSize = sizeTrigger ? "5xl" : "4xl";

  useEffect(() => {
    const [pageLocationGA, pageTitleGA] = ["2", "Step 2 - Upload Selfie"];

    collectAnalytics("page_view", {
      page_location: pageLocationGA,
      page_title: pageTitleGA,
    });
  }, []);

  useEffect(() => {
    if (isWtmError) {
      setHasError(true);
      setErrorMessage(t("error.oops"));
    }
  }, [isWtmError]);

  const usePreviousSelfie = () => {
    dispatch(updateCurrentSource(previousSelfie));
    dispatch(updateCurrentSourceBlob(previousSelfieBlob));
    dispatch(setSourceValid(true));
  };

  useEffect(() => {
    let stageUpdate: ReturnType<typeof setTimeout>;
    if (preview) {
      stageUpdate = setTimeout(() => {
        dispatch(nextStage());
      }, 1500);
    }

    return () => {
      if (stageUpdate) clearTimeout(stageUpdate);
    };
  }, [preview, dispatch]);

  const onDialogClose = () => {
    dispatch(setFailed(false));
    setHasError(false);
    setErrorMessage("");
  };

  return (
    <div className={`h-full min-h-full w-full`}>
      <Dialogue
        title=""
        setOpen={setHasError}
        content={errorMessage}
        open={hasError}
        closeButtonContent="Close"
        onClose={onDialogClose}
      />
      {preview ? (
        <div
          style={{ backgroundImage: `url(${preview.url})` }}
          className={`w-full bg-cover bg-center h-full`}
        />
      ) : isProcessing ? (
        <div
          className={`w-full h-full min-h-full flex flex-col items-center justify-center gap-4`}
        >
          <Typography className="text-white">
            {t("generic.validation")}
          </Typography>
          <Loader />
        </div>
      ) : (
        <div
          className={`flex relative justify-center flex-col w-full h-[70%] items-start px-6 top-[100px] ${
            sizeTrigger ? "gap-5" : "gap-3"
          }`}
        >
          <Typography
            className="font-bold whitespace-break-spaces text-mainTextLightColor"
            size="4xl"
          >
            {t("selfieSelection.title")}
          </Typography>
          <div className="flex w-full items-center flex-row gap-2 h-full m-auto relative">
            <div
              style={{
                backgroundImage: `url(${targetImage})`,
              }}
              className="w-full h-60 object-cover bg-center bg-no-repeat bg-cover rounded-3xl"
            />
            <Circle
              id={"+"}
              size="sm"
              className={
                "absolute ml-auto mr-auto left-0 right-0 bg-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] border-white shadow flex items-center justify-center border-2 z-50 p-4"
              }
            >
              <Typography
                color="text-gray-900"
                className="h-full text-4xl leading-[0.5rem]"
              >
                +
              </Typography>
            </Circle>
            <div className="w-full h-60 relative">
              <UploadImage
                isSource
                text={t("selfieSelection.description")}
                sizeTrigger={sizeTrigger}
                setHasError={setHasError}
                setErrorMessage={setErrorMessage}
                errorMessage={"Invalid image"}
              />
              {!!previousSelfie && (
                <div
                  style={{ backgroundImage: `url(${previousSelfie.url})` }}
                  className="bg-cover rounded-xl absolute top-2 right-2 w-12 h-12"
                  onClick={usePreviousSelfie}
                  role="none"
                >
                  <div className="w-full h-full rounded-xl hover:bg-transparent bg-white opacity-60 pointer-events-none" />
                </div>
              )}
            </div>
            {/* <Circle
              className="border border-gray-100 shadow-button cursor-pointer"
              filled={false}
              id="selfie-selection-camera"
              size={buttonSize}
              action={() => cameraRef.current?.click()}
            >
              <div className="flex flex-col items-center gap-2">
                <img
                  className={sizeTrigger ? "w-8" : "w-6"}
                  src={process.env.PUBLIC_URL + "/icons/camera-black.svg"}
                  alt="camera"
                />
                <input
                  className="hidden"
                  type="file"
                  accept="image/*"
                  onChange={validateAndUpload}
                  capture="user"
                  ref={cameraRef}
                />
              </div>
            </Circle> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadSelfie;
