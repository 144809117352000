import React from "react";

const Loading3Icon = (className: string) => {
  return (
    <svg
      width="807"
      height="107"
      viewBox="0 0 807 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="1.5"
        y="78.5"
        width="804"
        height="27"
        rx="13.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <rect
        x="6.5"
        y="83.5"
        width="737"
        height="17"
        rx="8.5"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M671.637 3C655.077 3 646.044 26.7778 634 35.6944C635.505 41.6389 647.549 71.3611 688.197 71.3611C728.845 71.3611 742.394 40.1528 740.889 35.6944C733.361 28.2639 718.306 3 706.263 3C692.713 3 691.958 8.94444 688.95 10.4306C686.014 8.94444 683.754 3 671.637 3Z"
        fill="#B23B3B"
        fillOpacity="0.94"
        stroke="#B23B3B"
        strokeWidth="3"
      />
      <path
        d="M663.002 38.2161C658.729 40.0101 654.457 41.8041 640.215 38.2161"
        stroke="black"
      />
      <path
        d="M712.719 38.2161C716.992 40.0101 721.264 41.8041 735.506 38.2161"
        stroke="black"
      />
      <path
        d="M687.86 42.3592C679.781 42.3592 673.049 36.1446 660.93 39.2519C664.97 37.6982 671.702 29.9299 677.088 29.9299C682.474 29.9299 685.841 33.0373 688.534 33.0373C691.227 33.0373 693.246 29.9299 699.979 29.9299C706.711 29.9299 709.404 36.1446 714.79 39.2519C705.365 37.6982 695.939 42.3592 687.86 42.3592Z"
        fill="black"
        stroke="#0D0100"
      />
      <path
        d="M657.905 53.6167L660.188 54.7307L674.533 59.5131C674.787 59.5979 675.05 59.6546 675.317 59.6824L676.862 59.8432C677.472 59.9067 678.057 59.582 678.326 59.0304C678.456 58.7645 678.377 58.4438 678.139 58.2683L676.484 57.0504C676.335 56.9406 676.176 56.8444 676.009 56.7631L659.914 48.9117L658.656 48.2983C657.825 47.8926 656.928 47.636 656.007 47.5402L655.918 47.5308L655.453 47.4825C654.842 47.4189 654.257 47.7436 653.988 48.2952C653.707 48.8724 653.717 49.5493 654.017 50.1172L654.173 50.4121C654.29 50.6332 654.438 50.8368 654.611 51.0169L655.267 51.6979C656.027 52.4869 656.92 53.1364 657.905 53.6167Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default Loading3Icon;
