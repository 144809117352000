import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Common/Button/Button";
import VIcon from "../Common/CustomIcons/VIcon";
import Typography from "../Common/Typography/Typography";
import { IS_ADD_TO_CART } from "../../config";
import { sendAddToCartMessage } from "../../utils/discoveryHelper";

const AddToCartButton = ({
  onBuyNow,
  className,
  itemsIds,
}: {
  onBuyNow?: () => void;
  className?: string;
  itemsIds: string[];
}) => {
  const { t } = useTranslation();
  const [addedToCart, setAddedToCart] = useState(false);

  const addToCart = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (IS_ADD_TO_CART) {
      setAddedToCart(true);
      sendAddToCartMessage(itemsIds);
      setTimeout(() => setAddedToCart(false), 2000);
    } else {
      onBuyNow?.();
    }
  };

  const buyNowText = addedToCart
    ? "discovery.catalog.added"
    : IS_ADD_TO_CART
    ? "discovery.catalog.add"
    : "discovery.catalog.buyNow";

  return (
    <Button
      className={`py-0 w-32 h-8 flex gap-2 items-center ${className}`}
      size="small"
      action={addToCart}
      bgColor="buttonBg"
    >
      {addedToCart && VIcon("")}
      <Typography
        className="py-0 leading-[normal]"
        color="text-white"
        size="sm"
      >
        {t(buyNowText)}
      </Typography>
    </Button>
  );
};

export default AddToCartButton;
