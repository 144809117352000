/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { TouchEvent, useEffect, useRef, useState } from "react";

export type BottomPanelProps = {
  isDraggable: boolean;
  children: React.ReactNode;
  topContent?: React.ReactNode;
  setExportedZoom?: (toSet: boolean) => void;
  shouldZoom?: boolean;
  filters?: boolean;
  catalog?: boolean;
  onClose?: () => void;
};

const baseID = "mistrix-bottom-panel";

const BottomPanel: React.FC<BottomPanelProps> = ({
  isDraggable,
  children,
  topContent,
  setExportedZoom,
  shouldZoom,
  filters,
  catalog,
  onClose,
}) => {
  const [zoom, setZoom] = useState(shouldZoom ? shouldZoom : false);
  const [startingPos, setStartingPos] = useState<number>(0);
  const supportsTouch =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;
  const [pageHeight, setPageHeight] = useState<number>(window.innerHeight);
  let minPosition: number;

  const useOnClickOutside = (
    ref: React.RefObject<HTMLDivElement>,
    onClick: (e?: MouseEvent) => void,
  ) => {
    useEffect(() => {
      const onClickOutside = (event: MouseEvent) => {
        if (
          //@ts-ignore: event type
          event.target.getAttribute("data-testid") ===
          "mistrix-bottom-panel-overlay"
        ) {
          onClick(event);
        }
      };
      document.addEventListener("mousedown", onClickOutside);

      return () => document.removeEventListener("mousedown", onClickOutside);
    }, [ref, onClick]);
  };

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => updateZooms(false));

  useEffect(() => {
    const handleResize = () => {
      setPageHeight(window.innerHeight);
    };

    // Set up resize event listener for window
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!catalog) {
    minPosition = pageHeight * 0.3;
  } else {
    minPosition = pageHeight * 0.4;
  }

  const [position, setPosition] = useState(minPosition);
  const maxPosition = pageHeight * 0.8;

  const onDragStart = (e: TouchEvent<HTMLDivElement>) => {
    const { clientY } = e.changedTouches[0];
    setStartingPos(clientY);
  };

  const onDrag = (e: TouchEvent<HTMLDivElement>) => {
    if (!isDraggable) return;

    const newPosition = pageHeight - e.touches[0].clientY;

    if (newPosition >= position && newPosition <= maxPosition) {
      setPosition(newPosition);
    } else if (newPosition <= position && newPosition >= minPosition) {
      setPosition(newPosition);
    }
  };

  const updateZooms = (toUpdate: boolean) => {
    setZoom(toUpdate);
    setExportedZoom?.(toUpdate);
    onClose?.();
  };

  const onDragEnd = (e: TouchEvent<HTMLDivElement>) => {
    const endPos = e.changedTouches[0].clientY;
    if (Math.abs(endPos - startingPos) > pageHeight * 0.2) {
      updateZooms(endPos < startingPos);
      setPosition(endPos < startingPos ? maxPosition : minPosition);
    } else if (zoom) {
      setPosition(maxPosition);
    } else {
      setPosition(minPosition);
    }
  };

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   const { scrollTop } = event.currentTarget;
  //   if (zoom && scrollTop <= 0) {
  //     updateZooms(false);
  //   }
  // };

  const openPanel = () => !zoom && updateZooms(true);

  useEffect(() => {
    if (zoom || (!!shouldZoom && shouldZoom)) {
      setPosition(maxPosition);
      setZoom(shouldZoom ? shouldZoom : true);
    } else {
      setPosition(minPosition);
      setZoom(false);
    }
  }, [zoom, maxPosition, minPosition, shouldZoom]);

  return (
    <>
      {zoom && (
        <div
          data-testid={baseID + "-overlay"}
          className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-75"
        />
      )}
      <div
        data-testid={baseID + (isDraggable ? "-draggable" : "")}
        style={{
          height: `${position}px`,
          minHeight: !isDraggable ? "250px" : "",
        }}
        ref={ref}
        className="rounded-t-3xl fixed bottom-0 left-0 w-full bg-bottomDrawer flex items-center flex-col shadow-[0_-10px_22px_0px_rgba(0,0,0,0.1)]"
      >
        {isDraggable ? (
          <>
            <div
              onClick={
                !supportsTouch
                  ? () => updateZooms(!zoom)
                  : () => {
                      return;
                    }
              }
              onTouchStart={onDragStart}
              onTouchMove={onDrag}
              onTouchEnd={onDragEnd}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !supportsTouch) {
                  updateZooms(!zoom);
                }
              }}
              role="button"
              tabIndex={0}
              className={`w-full ${
                !catalog ? "pb-4 pt-4" : "pt-2 pb-2"
              } flex justify-center cursor-pointer`}
            >
              <div
                data-testid={baseID + "-interactor"}
                className="h-1 w-1/6 bg-gray-300 border-r-8 border-gray-300 rounded-md"
              />
            </div>
            {catalog ? (
              <div
                onClick={
                  !supportsTouch
                    ? () => updateZooms(!zoom)
                    : () => {
                        return;
                      }
                }
                onTouchStart={onDragStart}
                onTouchMove={onDrag}
                onTouchEnd={onDragEnd}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !supportsTouch) {
                    updateZooms(!zoom);
                  }
                }}
                className="relative z-50 w-full mt-2 pb-4 pl-4 pr-4 rounded-t-3xl"
              >
                {topContent}
              </div>
            ) : (
              <div
                onTouchStart={onDragStart}
                onTouchMove={onDrag}
                onTouchEnd={onDragEnd}
                className="relative z-50 w-full pl-4 pr-4"
              >
                {topContent}
              </div>
            )}
          </>
        ) : (
          <div
            onTouchStart={onDragStart}
            onTouchMove={onDrag}
            onTouchEnd={onDragEnd}
            onClick={openPanel}
            className="relative z-50 w-full mt-4 pb-4 pl-4 pr-4 rounded-t-3xl"
          >
            {topContent}
          </div>
        )}
        <div
          data-testid={baseID + "-content-container"}
          // onClick={openPanel}
          onDrag={openPanel}
          // onTouchStart={openPanel}
          onTouchMove={openPanel}
          className={`${
            zoom || !isDraggable
              ? "overflow-y-scroll scrollbar"
              : "overflow-y-clipX scrollbar"
          } ${catalog ? "flex flex-col items-center" : ""} w-full pl-4 pr-4 ${
            filters ? "pt-12" : "pt-2"
          } mb-8 h-full`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default BottomPanel;
