import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Common/Button/Button";
import Typography from "../Common/Typography/Typography";
import Dialogue from "../Dialogue/Dialogue";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setSourceUploaded,
  setSourceValid,
  setStage,
  setTargetUploaded,
  setTargetValid,
} from "../../redux/flow";
import {
  updateCurrentSource,
  updateCurrentSourceBlob,
  updatePreviousSource,
  updatePreviousSourceBlob,
  updateTarget,
  updateTargetBlob,
} from "../../redux/images";
import { updateCatalog } from "../../redux/discovery";
import { collectAnalytics } from "../../utils/apiUtils";
import Hotjar from "@hotjar/browser";

interface ExitDialogProps {
  open: boolean;
  setExitDialogOpen: (value: boolean) => void;
}

const ExitDialog: React.FC<ExitDialogProps> = ({
  open,
  setExitDialogOpen,
}: ExitDialogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const appStage = useAppSelector((state) => state.flow.currentStage);

  const backToStart = () => {
    if (appStage === 4) {
      collectAnalytics("user_back_to_start");
      Hotjar.event("user_back_to_start");
    }
    dispatch(setStage(1));
    dispatch(updateTarget(null));
    dispatch(updateTargetBlob(null));
    dispatch(updateCurrentSource(null));
    dispatch(updateCurrentSourceBlob(null));
    dispatch(setTargetValid(false));
    dispatch(setSourceValid(false));
    dispatch(setTargetUploaded(false));
    dispatch(setSourceUploaded(false));
    dispatch(updateCatalog([]));
  };

  const onAppClose = () => {
    if (appStage === 4) {
      collectAnalytics("user_exit");
      Hotjar.event("user_exit");
    }
    window.parent.postMessage({ name: "closeMistrixIframe" }, "*");
    dispatch(updatePreviousSourceBlob(null));
    dispatch(updatePreviousSource(null));
    backToStart();
  };

  const exitOptions = [
    <Button
      key={0}
      action={() => backToStart()}
      bgColor="ghost"
      className="w-full hover:bg-gray-800 flex items-center"
    >
      <Typography className="leading-[normal]">
        {t("generic.exitDialog.back")}
      </Typography>
    </Button>,
    <Button
      key={1}
      action={onAppClose}
      bgColor="buttonBg"
      className="w-full hover:bg-gray-800 flex items-center"
    >
      <Typography color="white" className="leading-[normal]">
        {t("generic.exitDialog.leave")}
      </Typography>
    </Button>,
  ];

  return (
    <Dialogue
      reverseOptions
      options={appStage > 1 && appStage !== 3 ? exitOptions : [exitOptions[1]]}
      open={open}
      setOpen={setExitDialogOpen}
      title={t("generic.exitDialog.title")}
      content={t("generic.exitDialog.content")}
      closeButtonContent={t("generic.exitDialog.stay")}
    />
  );
};

export default ExitDialog;
