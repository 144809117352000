import React from "react";

const HeartIcon = (className: string) => {
  return (
    <svg
      width="66"
      height="56"
      viewBox="0 0 66 56"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="path-1-inside-1_2099_2974" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.62618 28.6705L33.27 55.7783L56.071 32.5265L56.0708 32.5263L60.3777 28.1343C66.674 21.7134 66.5731 11.4042 60.1522 5.10787C53.7314 -1.18843 43.4222 -1.08749 37.1259 5.33333L32.819 9.72539L28.4271 5.41869C22.0063 -0.877614 11.697 -0.776673 5.40072 5.64415C-0.895583 12.065 -0.79464 22.3742 5.62618 28.6705Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62618 28.6705L33.27 55.7783L56.071 32.5265L56.0708 32.5263L60.3777 28.1343C66.674 21.7134 66.5731 11.4042 60.1522 5.10787C53.7314 -1.18843 43.4222 -1.08749 37.1259 5.33333L32.819 9.72539L28.4271 5.41869C22.0063 -0.877614 11.697 -0.776673 5.40072 5.64415C-0.895583 12.065 -0.79464 22.3742 5.62618 28.6705Z"
        fill="CurrentColor"
      />
      <path
        d="M33.27 55.7783L29.7693 59.3483L33.3393 62.849L36.84 59.2791L33.27 55.7783ZM5.62618 28.6705L2.12543 32.2405L5.62618 28.6705ZM56.071 32.5265L59.6409 36.0272L63.1417 32.4572L59.5717 28.9565L56.071 32.5265ZM56.0708 32.5263L52.5008 29.0256L49.0001 32.5955L52.5701 36.0963L56.0708 32.5263ZM32.819 9.72539L29.3182 13.2954L32.8882 16.7961L36.389 13.2261L32.819 9.72539ZM36.7708 52.2083L9.12693 25.1006L2.12543 32.2405L29.7693 59.3483L36.7708 52.2083ZM52.501 29.0257L29.7001 52.2776L36.84 59.2791L59.6409 36.0272L52.501 29.0257ZM52.5701 36.0963L52.5702 36.0964L59.5717 28.9565L59.5716 28.9563L52.5701 36.0963ZM56.8077 24.6335L52.5008 29.0256L59.6408 36.0271L63.9477 31.635L56.8077 24.6335ZM56.6515 8.67785C61.1007 13.0407 61.1706 20.1843 56.8077 24.6335L63.9477 31.635C72.1774 23.2425 72.0455 9.7676 63.653 1.53789L56.6515 8.67785ZM40.6958 8.83408C45.0587 4.3849 52.2023 4.31496 56.6515 8.67785L63.653 1.53789C55.2605 -6.69182 41.7856 -6.55988 33.5559 1.83258L40.6958 8.83408ZM36.389 13.2261L40.6958 8.83408L33.5559 1.83258L29.249 6.22464L36.389 13.2261ZM24.9264 8.98867L29.3182 13.2954L36.3197 6.15541L31.9279 1.84871L24.9264 8.98867ZM8.9707 9.1449C13.3336 4.69572 20.4772 4.62578 24.9264 8.98867L31.9279 1.84871C23.5354 -6.381 10.0605 -6.24907 1.83074 2.1434L8.9707 9.1449ZM9.12693 25.1006C4.67775 20.7377 4.60781 13.5941 8.9707 9.1449L1.83074 2.1434C-6.39897 10.5359 -6.26703 24.0108 2.12543 32.2405L9.12693 25.1006Z"
        fill="black"
        mask="url(#path-1-inside-1_2099_2974)"
      />
    </svg>
  );
};

export default HeartIcon;
