import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductDiscoveryItem } from "../api/types";
import { Comboes, LookEffect, LookType, PartOfLook } from "../Types/discovery";

export interface DiscoveryState {
  catalog: ProductDiscoveryItem[];
  comboData: Comboes | null;
  imageTargetCropped: string;
  priceRange: { min: number; max: number };
  targetCoords: Record<PartOfLook, (number | null)[]>;
  sourceLipsCoords: (number | null)[];
  glossProduct: ProductDiscoveryItem | null;
  initialType: LookType;
  initialEffect: LookEffect;
  initialPartOfLook: PartOfLook | null;
}

const initialState: DiscoveryState = {
  catalog: [],
  comboData: null,
  imageTargetCropped: "",
  priceRange: { min: 0, max: 100 },
  targetCoords: {
    [PartOfLook.TOTAL_LOOK]: [],
    [PartOfLook.FACE]: [],
    [PartOfLook.EYES]: [],
    [PartOfLook.LIPS]: [],
  },
  sourceLipsCoords: [],
  glossProduct: null,
  initialType: LookType.MATTE,
  initialEffect: LookEffect.LARGE,
  initialPartOfLook: null,
};

export const discoverySlice = createSlice({
  name: "discovery",
  initialState,
  reducers: {
    updateCatalog: (state, action: PayloadAction<ProductDiscoveryItem[]>) => {
      state.catalog = action.payload;
    },
    updateComboes: (state, action: PayloadAction<Comboes>) => {
      state.comboData = action.payload;
    },
    updateTargetImageCrop: (state, action: PayloadAction<string>) => {
      state.imageTargetCropped = action.payload;
    },
    updatePriceMin: (state, action: PayloadAction<number>) => {
      state.priceRange.min = action.payload;
    },
    updatePriceMax: (state, action: PayloadAction<number>) => {
      state.priceRange.max = action.payload;
    },
    updatePriceRange: (
      state,
      action: PayloadAction<{ min: number; max: number }>,
    ) => {
      state.priceRange = action.payload;
    },
    updateTargetCoords: (
      state,
      action: PayloadAction<Record<PartOfLook, (number | null)[]>>,
    ) => {
      state.targetCoords = action.payload;
    },
    updateSourceLipsCoords: (
      state,
      action: PayloadAction<(number | null)[]>,
    ) => {
      state.sourceLipsCoords = action.payload;
    },
    updateGloss: (
      state,
      action: PayloadAction<ProductDiscoveryItem | null>,
    ) => {
      state.glossProduct = action.payload;
    },
    updateLookType: (state, action: PayloadAction<LookType>) => {
      state.initialType = action.payload;
    },
    updateLookEffect: (state, action: PayloadAction<LookEffect>) => {
      state.initialEffect = action.payload;
    },
    updatePartOfLook: (state, action: PayloadAction<PartOfLook>) => {
      return { ...state, initialPartOfLook: action.payload };
    },
  },
});

export const {
  updateCatalog,
  updateComboes,
  updateTargetCoords,
  updateSourceLipsCoords,
  updatePriceMax,
  updatePriceMin,
  updatePriceRange,
  updateTargetImageCrop,
  updateGloss,
  updateLookType,
  updateLookEffect,
  updatePartOfLook,
} = discoverySlice.actions;

export default discoverySlice.reducer;
