import React from "react";

const Loading2Icon = (className: string) => {
  return (
    <svg
      width="807"
      height="106"
      viewBox="0 0 807 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M463.576 2C447.042 2 438.024 25.7391 426 34.6413C427.503 40.5761 439.527 70.25 480.109 70.25C520.691 70.25 534.218 39.0924 532.715 34.6413C525.2 27.2228 510.169 2 498.145 2C484.618 2 483.864 7.93478 480.86 9.41848C477.929 7.93478 475.673 2 463.576 2Z"
        fill="#B23B3B"
        fillOpacity="0.94"
        stroke="#B23B3B"
        strokeWidth="3"
      />
      <path
        d="M454.954 37.1589C450.688 38.95 446.423 40.7411 432.204 37.1589"
        stroke="black"
      />
      <path
        d="M504.591 37.1589C508.856 38.95 513.122 40.7411 527.341 37.1589"
        stroke="black"
      />
      <path
        d="M479.773 41.2956C471.707 41.2956 464.985 35.091 452.886 38.1933C456.919 36.6422 463.641 28.8865 469.018 28.8865C474.395 28.8865 477.756 31.9887 480.445 31.9887C483.133 31.9887 485.15 28.8865 491.871 28.8865C498.593 28.8865 501.282 35.091 506.659 38.1933C497.249 36.6422 487.839 41.2956 479.773 41.2956Z"
        fill="black"
        stroke="#0D0100"
      />
      <rect
        x="1.5"
        y="77.5"
        width="804"
        height="27"
        rx="13.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <rect
        x="6.5"
        y="82.5"
        width="525"
        height="17"
        rx="8.5"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};

export default Loading2Icon;
