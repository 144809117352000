import React from "react";

const RightIcon = (className: string) => {
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.4375 8.5H8.03125M9.4375 8.5L1 16M9.4375 8.5L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightIcon;
