import React from "react";
import Typography from "../../../Common/Typography/Typography";

interface LookTypeProps {
  active: boolean;
  text: string;
  onClick?: (e?: React.MouseEvent) => void;
  className?: string;
}

const LookTypeButton: React.FC<LookTypeProps> = ({
  active,
  text,
  onClick,
  className,
}) => {
  const colorCN = active
    ? "bg-buttonBg hover:bg-gray-800"
    : "bg-white hover:bg-gray-200";
  return (
    <button
      className={`${colorCN} ${className} flex shadow-lg rounded-full max-w-[4.9rem] h-6 relative z-10 border border-buttonBg`}
      onClick={onClick}
    >
      <Typography
        className="leading-7 px-2"
        size="xs"
        color={active ? "text-white" : "text-buttonBg"}
      >
        {text}
      </Typography>
    </button>
  );
};

export default LookTypeButton;
