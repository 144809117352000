import { SortMethods, Product } from "../Types/discovery";
import { ProductDiscoveryItem } from "../api/types";
import { collectAnalytics } from "../utils/apiUtils";

export const sortCatalog = (
  toSort: Product[],
  sortMethod: SortMethods,
): Product[] => {
  switch (sortMethod) {
    case SortMethods.SIMILARITY:
      return [...toSort].sort((a, b) => b.match - a.match);
    case SortMethods.PRICE_ASCENDING:
      return [...toSort].sort((a, b) =>
        b.product.price && a.product.price
          ? parseInt(a.product.price) - parseInt(b.product.price)
          : 0,
      );
    case SortMethods.PRICE_DECENDING:
      return [...toSort].sort((a, b) =>
        b.product.price && a.product.price
          ? parseInt(b.product.price) - parseInt(a.product.price)
          : 0,
      );
  }
};

export const delay = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const findProductInDiscovery = (
  productID: string,
  catalog: ProductDiscoveryItem[],
): number => {
  return catalog.findIndex((item) => item.product_id === productID);
};

export const checkProduct = (product: ProductDiscoveryItem) => () => {
  if (product.product_url) {
    window.open(product.product_url, "_blank", "noreferrer");
    collectAnalytics("clicked_on_product_link", product);
    collectAnalytics("add_to_cart", {
      items: [
        {
          item_id: product.product_id,
          item_name: product.product_name,
          affiliation: product.supplier,
          coupon: product.client,
          item_brand: product.brand,
          item_category: product.lips,
          item_category2: product.eyes,
          item_category3: product.face,
          item_variant: product.color_name,
          price: product.price,
          quantity: 1,
        },
      ],
    });
  }
};

export const sendAddToCartMessage = (productIds: string[]) => {
  window.parent.postMessage(
    {
      name: "mistrixAddToCart",
      payload: { products: productIds },
    },
    "*",
  );
};

export const sendAddToFavoritesMessage = (productId: string, add: boolean) => {
  window.parent.postMessage(
    {
      name: "mistrixAddToFavorites",
      payload: { productId, add },
    },
    "*",
  );
};

export const getIsInFavoritesMessage = (productId: string) => {
  window.parent.postMessage(
    {
      name: "mistrixIsInFavorites",
      payload: { productId },
    },
    "*",
  );
};
